import { Pipe, PipeTransform } from '@angular/core';
import { BBBUser } from '@reflact/kick';
import { UserService } from '../user-admin/user.service';

@Pipe({
  name: 'nameById',
})
export class NameByIdPipe implements PipeTransform {
  constructor(private service: UserService) { }
  public async transform(id: string): Promise<string> {
    const users: BBBUser[] = await this.service.getAllPortalUser();
    const user = users.find((u) => u._id === id);
    return user ? user.name : id;
  }
}
