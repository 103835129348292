<app-layout>
  <div class="container mt-4">
    <app-card>
      <tabset>
        <tab
          heading="Server"
          id="tab1"
        >
          <app-server-table></app-server-table>
        </tab>
        <tab heading="Lizenzen">
          <app-mandant-table></app-mandant-table>
        </tab>
      </tabset>
    </app-card>
  </div>
</app-layout>