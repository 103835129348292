import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './AuthService';

@Injectable({ providedIn: 'root' })
export class UseradminGuard {
  constructor(private service: AuthService, private router: Router) { }

  public canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const user = this.service.getUserObject();
    if (user == null) this.router.navigate(['/', 'login']);
    if (user == null || user.permissions == null) return false;
    return user.permissions.some((p) => p === 'admin');
  }
}