<div class="text-end">
  <div
    class="btn btn-primary"
    (click)="this.modalRef = this.modalService.show(createLicenseTpl)"
  >Neue Lizenz</div>
</div>
<lib-rag-datasupply-table-quicklook-aside
  #table
  [service]="frontendService"
  [columns]="[
    {fieldName: 'license.type', label: 'Typ', template:licenseType},
    {fieldName: 'license.name', label: 'Name', template:licenseName},
    {fieldName: 'license.count', label: 'Anzahl Nutzer', template:licenseCount},
    {fieldName: 'license.startDate', label: 'Anfangsdatum', template:licenseStartDate},
    {fieldName: 'license.endDate', label: 'Enddatum', template:licenseEndDate}
  ]"
>
  <ng-container *ngIf="table.selectedObject">
    <div class="px-3">
      <rag-form
        [data]="table.selectedObject"
        [formFields]="[
          {fieldName: 'license.name', type: 'text', label: 'Name', required: true},
          {fieldName: 'license.description', type: 'text', label: 'Beschreibung'},
          {fieldName: 'license.count', type: 'number', label: 'Anzahl', required: true},
          {fieldName: 'license.type', type: 'select', label: 'Typ', required: true, options: {
            selectValues: [{label: 'Named', value: 'named'}, {label: 'Concurrent', value: 'concurrent'}],
            bindLabel: 'label',
            bindValue: 'value'
          }},
          {fieldName: 'license.start_date', type: 'date', required: true, label: 'Anfangsdatum'},
          {fieldName: 'license.end_date', type: 'date', required: true, label: 'Enddatum'}
        ]"
        (delete)="deleteLicense($event)"
        (submit)="updateLicense($event)"
      ></rag-form>
    </div>
  </ng-container>
</lib-rag-datasupply-table-quicklook-aside>

<ng-template #createLicenseTpl>
  <div class="modal-body">


    <rag-form
      [data]="{
        license: {
            name: '',
            description: '',
            count: 0,
            type: undefined,
            start_date: undefined,
            end_date: undefined
        },
        type: 'kicklicense'
    }"
      [formFields]="[
      {fieldName: 'license.name', type: 'text', label: 'Name', required: true},
      {fieldName: 'license.description', type: 'text', label: 'Beschreibung'},
      {fieldName: 'license.count', type: 'number', label: 'Anzahl', required: true},
      {fieldName: 'license.type', type: 'select', label: 'Typ', required: true, options: {
        selectValues: [{label: 'Named', value: 'named'}, {label: 'Concurrent', value: 'concurrent'}],
        bindLabel: 'label',
        bindValue: 'value'
      }},
      {fieldName: 'license.start_date', type: 'date', required: true, label: 'Anfangsdatum'},
      {fieldName: 'license.end_date', type: 'date', required: true, label: 'Enddatum'}
    ]"
      type="create"
      (submit)="this.createLicense($event); modalRef.hide()"
    ></rag-form>
  </div>
</ng-template>

<ng-template
  #licenseName
  let-data="data"
>{{data.license.name}}</ng-template>

<ng-template
  #licenseCount
  let-data="data"
>{{data.license.count}}</ng-template>

<ng-template
  #licenseType
  let-data="data"
>
  <ng-container [ngSwitch]="data.license.type">
    <span *ngSwitchCase="'named'">
      <div class="badge bg-danger">Named</div>
    </span>
    <span *ngSwitchCase="'concurrent'">
      <div class="badge bg-primary">Concurrent</div>
    </span>
  </ng-container>
</ng-template>

<ng-template
  #licenseStartDate
  let-data="data"
>{{data.license.start_date|date: "dd.MM.YYYY"}}</ng-template>

<ng-template
  #licenseEndDate
  let-data="data"
>{{data.license.end_date|date: "dd.MM.YYYY"}}</ng-template>