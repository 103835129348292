import { UploadState } from 'ngx-uploadx';

export class Ufile {
  public name!: string;
  public uploadId!: string;
  public progress!: number;
  public status!: string;

  constructor(state: UploadState) {
    this.uploadId = state.uploadId;
    this.update(state);
  }

  public update(state: UploadState): void {
    this.name = state.name;
    this.progress = state.progress;
    this.status = state.status;
  }
}
