import { Component, Input, TemplateRef } from '@angular/core';
import { BbbRoom } from '@reflact/kick';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BbbService } from '../bbb.service';

@Component({
  selector: 'app-bbb-room-deletebutton',
  templateUrl: './bbb-room-deletebutton.component.html',
  styleUrls: ['./bbb-room-deletebutton.component.scss'],
})
export class BbbRoomDeletebuttonComponent {
  public modalRef: BsModalRef;
  @Input() public room: BbbRoom;
  constructor(
    private modalService: BsModalService,
    private service: BbbService,
  ) { }

  public openModal(template: TemplateRef<BsModalRef>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }

  public confirm(): void {
    this.modalRef.hide();
    this.service.deleteRoom(this.room._id);
  }

  public decline(): void {
    this.modalRef.hide();
  }
}
