import { Component, ViewChild } from '@angular/core';
import { BBBUser, BbbRoom } from '@reflact/kick';
import { DatasupplySort, RagDatasupplyFrontendService, RagDatasupplyTableComponent } from '@reflact/rag-datasupply';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { BbbService } from '../bbb.service';
import { LoginService } from '../shared/login.service';
import { UserService } from '../user-admin/user.service';

@Component({
  selector: 'app-bbb-list',
  templateUrl: './bbb-room-list.component.html',
  styleUrls: ['./bbb-room-list.component.scss'],
  providers: [RagDatasupplyFrontendService]
})
export class BbbRoomListComponent {
  public window = window;
  public editorIdToName: Map<string, string> = new Map();
  public roomIdToEditorString: Map<string, string> = new Map();
  public users: BBBUser[] = [];
  public userMap: Map<string, BBBUser> = new Map();
  public roomSearchString = '';
  public searchedRoomList: BbbRoom[] = [];
  public searched: boolean = false;
  @ViewChild('table') public table!: RagDatasupplyTableComponent<BbbRoom>;
  @ViewChild('staticTabs') public staticTabs!: TabsetComponent;

  constructor(
    public bbbService: BbbService,
    public userService: UserService,
    public dataSupplyService: RagDatasupplyFrontendService<BbbRoom>,
    public loginService: LoginService
  ) {
    this.loadUsers();
    this.dataSupplyService.fromArray(this.bbbService.rooms);
  }

  private async loadUsers() {
    this.users = await this.userService.getAllPortalUser();
    this.users.forEach(u => this.userMap.set(u._id, u));
  }

  public confirmCopy(e: MouseEvent): void {
    (e.currentTarget as HTMLElement).className = 'ri-checkbox-circle-fill text-success cursor-pointer';
    setTimeout(this.confirmCopyDone.bind(this, e.currentTarget), 500);
  }

  public confirmCopyDone(t: { className: string }) {
    t.className = 'ri-clipboard-line cursor-pointer';
  }

  public copyMessage(val: string) {
    navigator.clipboard.writeText(val);
  }

  public isRoomOwner(room: BbbRoom) {
    return room.creator === this.bbbService.getUserObject()._id;
  }

  public isAdmin(): boolean {
    return this.loginService.loggedInUser?.permissions?.includes('admin');
  }

  public async searchRooms() {
    this.searchedRoomList = await this.bbbService.searchRooms(encodeURIComponent(this.roomSearchString.trim()));
    this.searched = true;

  }

  public async addMeToRoom(room: BbbRoom) {
    await this.bbbService.addMyUserToRoom(room._id);
    this.staticTabs.tabs[0].active = true;
    this.dataSupplyService.fromArray(this.bbbService.rooms);
  }

  public sortRooms(sortEvent: DatasupplySort) {
    this.dataSupplyService.rawData.sort((a, b) => {
      if (sortEvent.sortDirection === 'asc') {
        return a[sortEvent.sortField].localeCompare(b[sortEvent.sortField]);
      } else if (sortEvent.sortDirection === 'desc') {
        return b[sortEvent.sortField].localeCompare(a[sortEvent.sortField]);
      }
    });
  }
}
