<i
  class="ri-folder-reduce-line cursor-pointer"
  type="button"
  tooltip="Freigabe verlassen"
  placement="top"
  container="body"
  (click)="openModal(template)"
>
</i>
<ng-template #template>
  <div class="modal-body text-center">
    <p>Diesen Raum verlassen?</p>
    <p>
      Der Raum wird <strong>nicht</strong> gelöscht, Sie verlieren nur die
      Möglichkeit diesen zu bearbeiten / anzusehen.
    </p>
    <button type="button" class="btn btn-default m-4" (click)="confirm()">
      Ja
    </button>
    <button type="button" class="btn btn-primary m-4" (click)="decline()">
      Nein
    </button>
  </div>
</ng-template>
