import { Component, Input } from '@angular/core';
import { BbbRoom } from '@reflact/kick';
import { BbbService } from '../../bbb.service';

@Component({
  selector: 'app-url-editor',
  templateUrl: './url-editor.component.html',
  styles: [],
})
export class UrlEditorComponent {
  @Input() public room: BbbRoom;
  public window = window;
  constructor(public bbbService: BbbService) { }
}
