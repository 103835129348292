import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxFormsModule } from '@reflact/ngx-forms';
import { NgxLayoutModule } from '@reflact/ngx-layout';
import { RagDatasupplyModule } from '@reflact/rag-datasupply';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ToastrModule } from 'ngx-toastr';
import { UploadxModule } from 'ngx-uploadx';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AttendancesListComponent } from './attendances-list/attendances-list.component';
import { BbbRoomCreateformComponent } from './bbb-room-createform/bbb-room-createform.component';
import { BbbRoomDeletebuttonComponent } from './bbb-room-deletebutton/bbb-room-deletebutton.component';
import { BbbRoomEditAdvancedComponent } from './bbb-room-edit-advanced/bbb-room-edit-advanced.component';
import { BbbRoomEditButtonComponent } from './bbb-room-edit-button/bbb-room-edit-button.component';
import { AccessEditorComponent } from './bbb-room-edit-form/access-editor/access-editor.component';
import {
  BbbRoomEditFormComponent,
  FormGroupComponent,
} from './bbb-room-edit-form/bbb-room-edit-form.component';
import { GuestListEditorComponent } from './bbb-room-edit-form/guest-list-editor/guest-list-editor.component';
import { MaxParticipantsEditorComponent } from './bbb-room-edit-form/max-participants-editor/max-participants-editor.component';
import { ModeratorInvatationKeyEditorComponent } from './bbb-room-edit-form/moderator-invatation-key-editor/moderator-invatation-key-editor.component';
import { NameEditorComponent } from './bbb-room-edit-form/name-editor/name-editor.component';
import { FileListComponent } from './bbb-room-edit-form/presentation-editor/file-list/file-list.component';
import { PresentationEditorComponent } from './bbb-room-edit-form/presentation-editor/presentation-editor.component';
import { RecordEditorComponent } from './bbb-room-edit-form/record-editor/record-editor.component';
import { RequireModeratorEditorComponent } from './bbb-room-edit-form/require-moderator-editor/require-moderator-editor.component';
import { SharedUsersEditorComponent } from './bbb-room-edit-form/shared-users-editor/shared-users-editor.component';
import { TelcoEditorComponent } from './bbb-room-edit-form/telco-editor/telco-editor.component';
import { UrlEditorComponent } from './bbb-room-edit-form/url-editor/url-editor.component';
import { WelcomeEditorComponent } from './bbb-room-edit-form/welcome-editor/welcome-editor.component';
import { BbbRoomLeavebuttonComponent } from './bbb-room-leavebutton/bbb-room-leavebutton.component';
import { BbbRoomListComponent } from './bbb-room-list/bbb-room-list.component';
import { BBBFrameComponent } from './bbbframe/bbbframe.component';
import { GlobalSettingsComponent } from './global-settings/global-settings.component';
import { JoinComponent } from './join/join.component';
import { JoincardComponent } from './join/joincard/joincard.component';
import { JoinpagebuttonComponent } from './join/joinpagebutton/joinpagebutton.component';
import { LayoutComponent } from './layout/layout.component';
import { LicenseAdminComponent } from './license-admin/license-admin.component';
import { LicenseManagementComponent } from './license-management/license-management.component';
import { MandantLicenseComponent } from './license-management/mandant-table/mandant-license/mandant-license.component';
import { MandantTableComponent } from './license-management/mandant-table/mandant-table.component';
import { ServerTableComponent } from './license-management/server-table/server-table.component';
import { LoginComponent } from './login/login.component';
import { PageBbbRoomsComponent } from './page-bbb-rooms/page-bbb-rooms.component';
import { RagLogoComponent } from './rag-logo/rag-logo.component';
import { RecordVideoPlaybackComponent } from './record-video-playback/record-video-playback.component';
import { RecGuestListComponent } from './recording-list/rec-guest-list/rec-guest-list.component';
import { RecordingListComponent } from './recording-list/recording-list.component';
import { BbbListResolver } from './shared/BbbListResolver';
import { FileSharingResolver } from './shared/FileSharingResolver';
import { JoinResolver } from './shared/JoinResolver';
import { LicenseResolver } from './shared/LicenseResolver';
import { LoginFromPortalResolver } from './shared/LoginFromPortalResolver';
import { CardComponent } from './shared/card/card.component';
import { HttpInterceptorService } from './shared/http-interceptor.service';
import { NameByIdPipe } from './shared/name-by-id.pipe';
import { SecondsToTimePipe } from './shared/seconds-to-time.pipe';
import { TokenGuard } from './shared/token.guard';
import { UserResolver } from './shared/user.resolver';
import { UseradminGuard } from './shared/useradmin.guard';
import { SidekickFileShareComponent } from './sidekick-file-share/sidekick-file-share.component';
import { UserAdminComponent } from './user-admin/user-admin.component';

@NgModule({ declarations: [
        AppComponent,
        BbbRoomListComponent,
        FormGroupComponent,
        BbbRoomCreateformComponent,
        BbbRoomDeletebuttonComponent,
        PageBbbRoomsComponent,
        JoinComponent,
        BbbRoomEditButtonComponent,
        BbbRoomEditFormComponent,
        RagLogoComponent,
        BbbRoomEditAdvancedComponent,
        WelcomeEditorComponent,
        MaxParticipantsEditorComponent,
        RecordEditorComponent,
        AccessEditorComponent,
        PresentationEditorComponent,
        RequireModeratorEditorComponent,
        NameEditorComponent,
        UrlEditorComponent,
        BBBFrameComponent,
        ModeratorInvatationKeyEditorComponent,
        LicenseAdminComponent,
        JoincardComponent,
        JoinpagebuttonComponent,
        SharedUsersEditorComponent,
        NameByIdPipe,
        GlobalSettingsComponent,
        BbbRoomLeavebuttonComponent,
        FileListComponent,
        SidekickFileShareComponent,
        TelcoEditorComponent,
        RecordingListComponent,
        AttendancesListComponent,
        UserAdminComponent,
        GuestListEditorComponent,
        LayoutComponent,
        CardComponent,
        LoginComponent,
        LicenseManagementComponent,
        ServerTableComponent,
        MandantTableComponent,
        MandantLicenseComponent,
        RecGuestListComponent,
        RecordVideoPlaybackComponent
    ],
    bootstrap: [AppComponent], imports: [CommonModule,
        FormsModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        UploadxModule,
        TooltipModule.forRoot(),
        ModalModule.forRoot(),
        TabsModule.forRoot(),
        ProgressbarModule.forRoot(),
        NgSelectModule,
        RagDatasupplyModule,
        NgxLayoutModule,
        NgxFormsModule,
        ToastrModule.forRoot({ preventDuplicates: true }),
        SecondsToTimePipe], providers: [
        BbbListResolver,
        JoinResolver,
        LicenseResolver,
        UserResolver,
        LoginFromPortalResolver,
        FileSharingResolver,
        NameByIdPipe,
        UseradminGuard,
        TokenGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
