<ng-template
  #userNameTpl
  let-data="data"
>
  {{ data.userName }}
</ng-template>
<ng-template
  #startDateTpl
  let-data="data"
>
  {{ dayjsHtml(data.attendanceStartDate).format("DD.MM.YYYY HH:mm:ss") }}
</ng-template>
<ng-template
  #endDateTpl
  let-data="data"
>
  {{ dayjsHtml(data.attendanceEndDate).format("DD.MM.YYYY HH:mm:ss") }}
</ng-template>
<ng-template
  #durationTpl
  let-data="data"
>
  {{ dayjsHtml(data.attendanceDuration).utc().format("HH:mm:ss") }}
</ng-template>
<app-layout>
  <app-card>
    <div class="text-end">
      <button
        class="btn btn-sm btn-primary m-1"
        (click)="exportAttedances()"
      >
        Exportieren
      </button>
    </div>
    <lib-rag-datasupply-table
      #attendanceTable
      [columns]="[
          { fieldName: 'userName', label: 'Nutzername', template: userNameTpl },
          {
            fieldName: 'attendanceStartDate',
            label: 'von',
            template: startDateTpl
          },
          {
            fieldName: 'attendanceEndDate',
            label: 'bis',
            template: endDateTpl
          },
          {
            fieldName: 'attendanceDuration',
            label: 'Dauer',
            template: durationTpl
          }
        ]"
      [service]="this.dataSupplyService"
      noDataText="Keine Anwesenheiten vorhanden."
      [overrideTableClasses]="''"
    >
    </lib-rag-datasupply-table>
  </app-card>
</app-layout>