import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { BbbService } from '../bbb.service';

@Injectable()
export class JoinResolver {
  constructor(private service: BbbService) { }

  public async resolve(route: ActivatedRouteSnapshot) {
    const id = route.params.id;
    const short = route.params.short;
    const guestId = route.params.guest_id;
    return await this.service.getJoinInfo(id, short, guestId);
  }
}
