import { Component, OnInit, ViewChild } from '@angular/core';
import { BbbRoom } from '@reflact/kick';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { BbbService } from '../bbb.service';

@Component({
  selector: 'app-form-grop',
  template: `<div class="form-group row">
    <div class="col-sm-3 col-form-label font-weight-bold text-right ">
      <ng-content select="[label]"></ng-content>
    </div>
    <div class="col-9">
      <ng-content select="[input]"></ng-content>
      <ng-content></ng-content>
    </div>
  </div> `,
})
export class FormGroupComponent { }
@Component({
  selector: 'app-bbb-room-edit-form',
  templateUrl: './bbb-room-edit-form.component.html',
  styleUrls: ['./bbb-room-edit-form.component.scss'],
})
export class BbbRoomEditFormComponent implements OnInit {
  @ViewChild('staticTabs', { static: false }) public staticTabs?: TabsetComponent;
  public roomId: string;
  public room: BbbRoom;
  public window = window;

  constructor(public service: BbbService) { }

  public async ngOnInit() {
    this.room = await this.service.getRoomInfo(this.roomId);
    if (this.room.guestList == undefined) {
      this.room.guestList = [];
    }
  }

  public originalOrder(): number {
    return 0;
  }

  public async updateRoom() {
    this.service.updateRoom(this.room);
  }

  public async updateList(event) {
    this.room.guestList = event;
    this.service.updateRoom(this.room);
  }

  public selectTab(tabId: number) {
    if (this.staticTabs?.tabs[tabId]) {
      this.staticTabs.tabs[tabId].active = true;
    }
  }
}