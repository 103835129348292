import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BBBUser, DefaultResponse, LoginRequest, LoginResponse } from '@reflact/kick';
import { waitSeconds } from '@reflact/tsutil';
import { saveAs } from 'file-saver';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public loggedInUser$ = new BehaviorSubject<BBBUser | undefined>(undefined);
  public loggedInUser: BBBUser | undefined = undefined;
  public token: string | undefined = undefined;
  public selectedMandant: string | undefined = undefined;

  constructor(private http: HttpClient) {
    const token = localStorage.getItem('bbbJWT');
    if (token != null) {
      this.token = token;
      this.setUser(token)
    }
  }

  public async sendLoginCodeForEmail(email: string): Promise<DefaultResponse> {
    const req = await firstValueFrom(this.http.post<LoginResponse>('/api/auth/verificationCode', { email }));
    return req;
  }

  public async loginWithCode(email: string, verificationCode: string, short: string = ''): Promise<LoginResponse> {
    const req: LoginRequest = { email, verificationCode };
    if (short != '') {
      req.short = short;
      this.selectedMandant = short;
    }

    const res = await firstValueFrom(this.http.post<LoginResponse>('/api/auth/login', req));
    if (res.status == "ok" && res.token) {
      this.setUser(res.token);
    }
    await waitSeconds(2);
    return res;
  }

  public setUser(token: string) {
    const user: BBBUser = jwtDecode(token);
    this.token = token;
    localStorage.setItem('bbbJWT', token);
    this.loggedInUser = user;
    this.loggedInUser$.next(user)
  }

  public async getUserInfo() {
    const res = await this.http.get<{ currentshort: string, availableShorts: string[], tanListCreated: string, tanListValidEntryCount: number }>("/api/myuser/info");
    return res;
  }

  public async selectMandant(mandantId: string) {
    const res = await firstValueFrom(this.http.post<LoginResponse>('/api/auth/changemandant', { short: mandantId }));

    if (res.status == "ok" && res.token) {
      this.setUser(res.token);
    }
    return res;
  }

  public async generateTanList() {
    const res = await firstValueFrom(this.http.post('/api/tan/generate', {}, { responseType: 'blob', observe: 'response' }));
    const contentDisposition = res.headers.get('content-disposition');
    const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim().replaceAll('"', "");
    saveAs(res.body, filename);
  }

  public async loginWithTan(email: string, tancode: string) {
    const req = { email, tancode };
    const res = await firstValueFrom(this.http.post<{ status: string, valid?: boolean, availableShorts?: string[], token?: string }>('/api/tan/login', req));
    if (res.status == "ok" && res.token) {
      this.setUser(res.token);
    }
    return res;
  }

  public async getTanNumber(email: string) {
    const res: { tanIndex?: number } = await firstValueFrom(this.http.post('/api/tan/select', { email: email }));
    return res;
  }

  public async checkTanActive(email: string) {
    const res = await firstValueFrom(this.http.post<{ allowed: boolean }>('/api/tan/check', { email: email }));
    return res.allowed;
  }

  public async deleteTanList() {
    const res = await firstValueFrom(this.http.post('/api/tan/delete', {}));
    return res;
  }
}