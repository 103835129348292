import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { RedirectService } from './redirect.service';

export type SidekickFile = {
  _id: string,
  length: number,
  filename: string,
  contentType: string,
  metadata: {
    roomId: string,
    lastModified: Date,
    downloadable: boolean
  }

}

@Injectable({
  providedIn: 'root',
})
export class SidekickFileService {
  constructor(public http: HttpClient, public redirectService: RedirectService) { }

  public getDownloadurl(token: string, fileid: string) {
    const url = '/api/presentation/downloadforsidekick/' + fileid + '/' + token;
    return url;
  }

  public async getFilesForSidekickToken(token: string) {
    const url = '/api/presentation/downloadableforsidekick/' + token;
    const files = await firstValueFrom(this.http.get<{ presentations: SidekickFile[] }>(url));
    return files.presentations;
  }
}