import { Component, Input } from '@angular/core';
import { BbbRoom } from '@reflact/kick';

@Component({
  selector: 'app-telco-editor',
  templateUrl: './telco-editor.component.html',
  styles: [],
})
export class TelcoEditorComponent {
  @Input() public room: BbbRoom & { telco: boolean };
}
