import { Component, OnInit } from '@angular/core';
import { AuthService } from './shared/AuthService';
import { BbbService } from './bbb.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private authService: AuthService, private bbbService: BbbService) { }

  public async ngOnInit(): Promise<void> {
    if (this.authService.getUserObject() !== null) {
      await this.bbbService.getMandantConfig();
    }
  }
}