import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { firstValueFrom } from "rxjs";

@Injectable()
export class LoginFromPortalResolver {
  constructor(private router: Router, public httpClient: HttpClient) { }

  public async resolve(route: ActivatedRouteSnapshot) {
    const portaltoken = route.queryParamMap.get("token");

    const bbbtokenResult = await firstValueFrom<{ bbbUserToken: string }>(this.httpClient.get<{ bbbUserToken: string }>("/api/bbbUserByPortalToken/" + portaltoken))
    //xxxx Hier neuen token holen

    if (bbbtokenResult.bbbUserToken != null) {
      localStorage.setItem('bbbJWT', bbbtokenResult.bbbUserToken);
    }
    this.router.navigate(["/"]);
  }
}