<table class="w-100 h-75">
  <tr>
    <td class="w-50">
      <!--
        ! DEMO MODUS UND ERROR BEHANDLUNG
        ! DEMO MODUS UND ERROR BEHANDLUNG
      -->
      <ng-container *ngIf="errorMessage; else loginsAndNoerrors">
        <!--
          !DEMO MODE
          !DEMO MODE
        -->
        <ng-container *ngIf="this.errorMessage === 'demomode'">
          <app-joincard
            [roomName]="room.name"
            warnText="Sie betreten den Raum im Demo Modus."
          >
            <app-joinpagebutton
              [autoClickDelay]="3500"
              (click)="joinRoom()"
            >Los gehts</app-joinpagebutton>
          </app-joincard>
        </ng-container>
        <!--
          !JOIN ERRORS
          !JOIN ERRORS
        -->
        <ng-container *ngIf="this.errorMessage !== 'demomode'">
          <app-joincard
            [roomName]="room.name"
            dangerText="Betreten nicht möglich![br] {{
              errorMap.get(errorMessage)
            }}"
          >
            <div
              class="row"
              *ngIf="this.errorMessage !== 'missingguestid'"
            >
              <div class="col text-center">
                <app-joinpagebutton (btnClick)="joinRoom()">Erneut versuchen</app-joinpagebutton>
              </div>
            </div>
          </app-joincard>
        </ng-container>
      </ng-container>
      <!--
          !NO ERRORS
       -->
      <ng-template #loginsAndNoerrors>
        <ng-container *ngIf="
            !this.jwtuser && this.room.access === 'internal';
            else notInternal
          ">
          <app-joincard infoText="Dieses Meeting ist Privat. Bitte anmelden.">
            <div class="row">
              <div class="col text-center">
                <ng-container *ngTemplateOutlet="haveAccountBtn"></ng-container>
              </div>
            </div>
          </app-joincard>
        </ng-container>

        <ng-template #notInternal>
          <!--
        MEIN RAUM
        MEIN RAUM
        MEIN RAUM
      -->
          <app-joincard
            *ngIf="this.autojoin"
            [roomName]="room.name"
            infoText="Sie werden jetzt in den Raum weitergeleitet."
          >
            <app-joinpagebutton
              [autoClickDelay]="1000"
              (btnClick)="joinRoom()"
            >Los gehts</app-joinpagebutton>
          </app-joincard>
          <!--
       NICHT MEIN RAUM
       NICHT MEIN RAUM
       NICHT MEIN RAUM
      -->
          <app-joincard
            *ngIf="!this.autojoin"
            [roomName]="room.name"
            [infoText]="
              !this.requestPasswordForGuests
                ? 'Bitte geben Sie ihren [b]Namen[/b] ein.'
                : 'Bitte geben Sie ihren [b]Namen[/b] und das [b]Gästepasswort[/b] ein.'
            "
          >
            <ng-container *ngTemplateOutlet="nameInput"></ng-container>
            <ng-container *ngIf="this.requestPasswordForGuests">
              <ng-container *ngTemplateOutlet="pwInput"></ng-container>
            </ng-container>
            <div class="row">
              <div class="col">
                <app-joinpagebutton
                  type="primary"
                  (btnClick)="joinRoom()"
                  [disabled]="name.length === 0"
                >Los gehts</app-joinpagebutton>
              </div>
              <div class="col-auto">
                <ng-container *ngTemplateOutlet="haveAccountBtn"></ng-container>
              </div>
            </div>
          </app-joincard>
        </ng-template>
      </ng-template>
    </td>
  </tr>
  <tr></tr>
</table>
<div
  _ngcontent-bhr-c25=""
  style="position: fixed; bottom: 0px; width: 100%"
  class="text-right small text-muted pe-2"
>
  with love by <a
    href="https://www.reflact.com"
    target="_blank"
  >reflact.com</a>
</div>
<ng-template #nameInput>
  <input
    type="text"
    class="form-control mb-2"
    [(ngModel)]="name"
    [disabled]="guestId"
    placeholder="Anzeigename"
  />
</ng-template>
<ng-template #pwInput>
  <input
    type="password"
    class="form-control mb-2"
    [(ngModel)]="guestPassword"
    placeholder="Passwort"
    (keyup)="this.invalidPassword = false"
    [ngClass]="{ 'is-invalid': this.invalidPassword }"
  />
</ng-template>
<ng-template #haveAccountBtn>
  <a
    *ngIf="!this.jwtuser"
    class="badge bg-light text-dark p-2"
    [routerLink]="['/', 'login', window.location.href]"
  >
    Ich habe einen Account <br />und möchte mich anmelden
  </a>
</ng-template>