import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { SidekickFileService } from './sidekick-file.service';

@Injectable()
export class FileSharingResolver {
  constructor(private service: SidekickFileService) { }

  public async resolve(route: ActivatedRouteSnapshot) {
    const sidekicktoken = route.params.sidekicktoken;
    const files = await this.service.getFilesForSidekickToken(sidekicktoken);
    return { sidekicktoken, files };
  }
}
