<app-layout>
  <div
    class="container mt-t"
    *ngIf="this.bbbService.recordingEvents.size > 0"
  >
    <app-card>
      <h2>Recording Status</h2>
      <table class="table">
        <thead>
          <tr>
            <th>Raumname</th>
            <th>Status</th>
            <th>Zeit</th>
            <th>Fortschritt</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let recordingEvent of this.bbbService.recordingEvents | keyvalue">
            <td>
              {{this.bbbService.roomsMap.get(recordingEvent.value.roomId).name}}
            </td>
            <td>
              {{this.bbbService.statusTranslation.get(recordingEvent.value.event).label}}
            </td>
            <td>
              {{recordingEvent.value.updatedAt | date: 'dd.MM.YYYY HH:mm'}}
            </td>
            <td>
              <div class="progress">
                <div
                  class="progress-bar progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  [ngStyle]="{'width': this.bbbService.statusTranslation.get(recordingEvent.value.event).percentage +'%'}"
                ></div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </app-card>
  </div>

  <div class="container mt-4">

    <app-card>
      <tabset
        [justified]="true"
        #staticTabs
        *ngIf="this.isAdmin()"
      >
        <tab heading="Recordings">
          <ng-container
            *ngTemplateOutlet="roomListTpl;context: {recordings: this.bbbService.recordings}"></ng-container>
        </tab>
        <tab heading="Alle Recordings (Admin)">
          <ng-container
            *ngTemplateOutlet="roomListTpl; context: {recordings: this.bbbService.allRecordings}"></ng-container>
        </tab>
      </tabset>
      <ng-container *ngIf="!this.isAdmin()">
        <ng-container *ngTemplateOutlet="roomListTpl;context: {recordings: this.bbbService.recordings}"></ng-container>
      </ng-container>
    </app-card>
  </div>
</app-layout>

<ng-template
  #roomListTpl
  let-recordings="recordings"
>
  <table class="table table-hover">
    <thead>
      <tr>
        <th style="width: 50%">Raumname</th>
        <th style="width: 20%">Erstellt am</th>
        <th style="width: 15%">Wird gelöscht</th>
        <th style="width: 15%">&nbsp;</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let recording of recordings">
        <td>
          {{ recording.metadata.roomName }}
        </td>
        <td>
          {{ dayjs(recording.uploadDate).format("DD.MM.YYYY HH:mm") }}
        </td>
        <td>
          <span [ngClass]="{'text-decoration-line-through text-muted': !recording.metadata.autoDelete}">
            am {{ dayjs(recording.uploadDate).add(2, "week").format("DD.MM.YYYY") }}
          </span>
          <span
            class="ms-1"
            (click)="this.changeDeleteOption(recording)"
            [ngClass]="{ 'cursor-pointer': this.checkExpireDate(recording.uploadDate) }"
            *ngIf="this.checkExpireDate(recording.uploadDate)"
          >
            <i
              class="ri-lock-fill"
              placement="top"
              tooltip="Löschen wieder aktivieren"
              *ngIf="!recording.metadata.autoDelete"
            ></i>
            <i
              class="ri-lock-unlock-fill"
              placement="top"
              tooltip="Löschen verhindern"
              *ngIf="recording.metadata.autoDelete"
            ></i>
          </span>
        </td>
        <td style="width: 50px">
          <a
            class="text-decoration-none text-reset me-2"
            (click)="this.openAftershowPartyModal(recording)"
          >
            <i
              class="ri-group-fill cursor-pointer"
              placement="top"
              tooltip="Aufzeichnungsempfänger"
              type="button"
            ></i>
          </a>
          <a class="text-decoration-none text-reset me-2">
            <i
              class="ri-clipboard-line cursor-pointer"
              (click)="this.copyGlobalLink(recording._id);this.confirmCopy($event)"
              placement="top"
              tooltip="Globaler Aufzeichnungslink kopieren"
              type="button"
            ></i>
          </a>
          <a
            [href]="'/api/downloadrecording/' + recording._id + '?token=' + authService.getToken()"
            [download]="recording.metadata.roomName + '.mp4'"
            style="text-decoration: none; color: black"
            class="me-2"
          >
            <i
              class="ri-download-line cursor-pointer"
              placement="top"
              tooltip="Download"
              type="button"
            ></i>
          </a>
          <a
            class="text-decoration-none text-reset"
            (click)="openModal(deleteTlp, recording._id)"
          >
            <i
              class="ri-delete-bin-2-fill cursor-pointer"
              placement="top"
              tooltip="Aufzeichnung löschen"
              type="button"
            ></i>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</ng-template>

<ng-template #deleteTlp>
  <div class="modal-body text-center">
    <p>Diese Aufzeichnung löschen?</p>
    <button
      type="button"
      class="btn btn-default m-4"
      (click)="confirm()"
    >
      Ja
    </button>
    <button
      type="button"
      class="btn btn-primary m-4"
      (click)="decline()"
    >
      Nein
    </button>
  </div>
</ng-template>