<app-layout>
  <div class="container">
    <ng-container *ngIf="this.bbbService.isTechCheck()">
      <div class="alert">
        <div class="row">
          <div class="col-12">
            <b>reflact kick! Technik-Check</b><br />
            <br />
            Dies ist der Technik-Check, um eine reibungslose Teilnahme an einem
            Online Meetings mit reflact-kick! zu garantieren.<br />
            <div class="row mt-4">
              <div class="col">
                Um den Test zu starten klicken Sie oben auf "<b>Technik-Check starten</b>"<br />
                <br /><br />
              </div>
            </div>
          </div>
          <div class="col-12">
            <b>Bitte prüfen Sie folgende Dinge</b><br />
            <br />
            <div class="row">
              <div class="col-12">
                <i class="ri-number-1 number-icon"></i>
                Browserzugriff auf Kamera und Mikrofon ermöglichen
                <br />
                <small><b>Anleitung: </b>
                  <a
                    class="me-2"
                    target="_blank"
                    style="text-decoration: none"
                    href="https://url.reflact.com/kick-techcheck-manual-chrome"
                  >
                    <i
                      class="ri-chrome-fill"
                      style="position: relative; top: 2px"
                    ></i>
                    Google Chrome</a>
                  <a
                    target="_blank"
                    style="text-decoration: none"
                    href="https://url.reflact.com/kick-techcheck-manual-edge"
                  >
                    <i
                      class="ri-edge-fill"
                      style="position: relative; top: 2px"
                    ></i>
                    Microsoft Edge</a>
                </small>
              </div>
              <div class="col-12 mt-4">
                <i class="ri-number-2 number-icon"></i>
                Echotest durchführen (Mikrofon / Lautsprecher aktivieren)
                <br />
                <img
                  alt="join with microphone"
                  class="mt-2"
                  src="assets/TechCheck_2.png"
                  width="50%"
                />
              </div>
              <div class="col-12 mt-4">
                <i class="ri-number-3 number-icon"></i>
                Kamera aktivieren <small>(optional)</small>
                <br />
                <img
                  alt="settings"
                  class="mt-2"
                  src="assets/TechCheck_3.png"
                  width="50%"
                />
              </div>
            </div>
            <br /><br />
            <b>Sie benötigen Hilfe?</b><br />
            <i
              class="ri-mail-fill me-2"
              style="position: relative; top: 2px"
            ></i>
            <a
              target="_blank"
              href="mailto:kick.support@reflact.com"
            >Support kontaktieren</a>
            <br />
            <i
              class="ri-customer-service-2-fill me-2"
              style="position: relative; top: 2px"
            ></i>0208-77-8-99-700
          </div>
        </div>
        <br />
      </div>
    </ng-container>
    <app-bbb-list *ngIf="!this.bbbService.isTechCheck()"></app-bbb-list>
    <p style="height: 10px"></p>
  </div>

</app-layout>