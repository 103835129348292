import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MandantConfigCollectionLicense } from '@reflact/kick';
import { RagDatasupplyFrontendService } from '@reflact/rag-datasupply';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LicenseService } from '../../license.service';

@Component({
  selector: 'app-mandant-license',
  templateUrl: './mandant-license.component.html',
  styles: [
  ],
  providers: [RagDatasupplyFrontendService]
})
export class MandantLicenseComponent implements OnInit, OnChanges {
  @Input() public short: string;
  @Input() public licenses: MandantConfigCollectionLicense[];
  @Output() public licenseChanges: EventEmitter<1 | 2 | 3> = new EventEmitter()

  public modalRef: BsModalRef
  constructor(public frontendService: RagDatasupplyFrontendService<MandantConfigCollectionLicense>, public licenseService: LicenseService, public modalService: BsModalService) { }


  public ngOnInit(): void {
    this.frontendService.fromArray(this.licenses)
  }


  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes.licenses?.firstChange) {
      this.frontendService.fromArray(this.licenses)
    }
  }

  public async deleteLicense(license: MandantConfigCollectionLicense) {
    await this.licenseService.deleteKickLicense(this.short, license._id);
    this.licenseChanges.emit(1);
  }
  public async updateLicense(license: MandantConfigCollectionLicense) {
    const { _id, ...newLicense } = license;
    await this.licenseService.updateKickLicense(this.short, _id, newLicense);
    this.licenseChanges.emit(2);
  }
  public async createLicense(creationData: Omit<MandantConfigCollectionLicense, "_id">) {
    await this.licenseService.createKickLicense(this.short, creationData);
    this.licenseChanges.emit(3);
  }
}
