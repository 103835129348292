import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './AuthService';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private service: AuthService) { }

  public intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const portalJWT = localStorage.getItem('bbbJWT');
    if (portalJWT == null || !this.service.getUserObject()) {
      return next.handle(req);
    }
    const modifiedRequest: HttpRequest<unknown> = req.clone({
      setHeaders: { token: portalJWT },
    });
    return next.handle(modifiedRequest);
  }
}
