import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BBBUser } from '@reflact/kick';
import { LicenseObject } from '../license-management/license.service';
import { AuthService } from '../shared/AuthService';
import { UserService } from '../user-admin/user.service';

@Component({
  selector: 'app-license-admin',
  templateUrl: './license-admin.component.html',
  styles: [],
})
export class LicenseAdminComponent {
  public licenses: LicenseObject[] = [];
  public namedHostSize = 0;
  public namedHostIds: string[] = [];
  public users: BBBUser[] = [];
  constructor(
    public aRoute: ActivatedRoute,
    public authService: AuthService,
    private userService: UserService,
  ) {
    aRoute.data.subscribe((d) => {
      this.licenses = d.licenses;
      this.users = d.users;
      this.namedHostSize = this.licenses
        .filter((l) => l.license.type === 'named')
        .map((l) => l.license.count)
        .reduce((a, b) => a + b, 0);
      this.namedHostIds = this.users
        .filter((u) => u.permissions.includes('namedhost'))
        .map((u) => u._id);
    });
  }

  public async editUserForNamedHost(input: BBBUser): Promise<void> {
    const permissions = input.permissions;
    const index = permissions.indexOf('namedhost');
    if (index > -1) {
      permissions.splice(index, 1);
    } else {
      permissions.push('namedhost');
    }
    await this.userService.editUser(input._id, { permissions });
  }
}
