<app-form-grop>
  <span label>Teilen mit</span>
  <ng-select
    [items]="portalUsers | async"
    [multiple]="true"
    [clearOnBackspace]="false"
    bindLabel="name"
    bindValue="_id"
    [(ngModel)]="room.editors"
    (ngModelChange)="onEditorsChanged()"
  >
  </ng-select>
  <small class="form-text text-muted">Personen denen dieser Raum geteilt wird, werden diesen bearbeiten und
    verändern können.</small>
</app-form-grop>