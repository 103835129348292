import { Component, Input } from '@angular/core';
import { BbbRoom } from '@reflact/kick';
import { BbbService } from 'src/app/bbb.service';

@Component({
  selector: 'app-moderator-invatation-key-editor',
  templateUrl: './moderator-invatation-key-editor.component.html',
  styles: [],
})
export class ModeratorInvatationKeyEditorComponent {
  @Input() public room: BbbRoom;
  public window = window;

  constructor(public bbbService: BbbService) { }

  public async onModeratorInvitationKeyActive() {
    if (!this.room.moderatorInvitationKey) {
      this.room.moderatorInvitationKey = await this.bbbService.getModeratorInvitationKey();
      this.bbbService.updateRoom(this.room);
    }
  }
}
