import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RedirectService } from './redirect.service';
import { Attendance } from '@reflact/kick';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AttendanceService {
  constructor(
    public httpClient: HttpClient,
    public redirectService: RedirectService,
  ) { }

  public async getAttendancesForBBBRoom(roomId: string): Promise<Attendance[]> {
    const url = '/api/attendancesForBBBRoom/' + roomId;

    return await firstValueFrom(this.httpClient.get<Attendance[]>(url));
  }
}
