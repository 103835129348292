import { Component, OnInit, TemplateRef } from '@angular/core';
import { BbbRoom } from '@reflact/kick';
import * as dayjs from 'dayjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BbbService, Recording } from 'src/app/bbb.service';
import { LoginService } from '../shared/login.service';
import { AuthService } from './../shared/AuthService';
import { RecGuestListComponent } from './rec-guest-list/rec-guest-list.component';

@Component({
  selector: 'app-recording-list',
  templateUrl: './recording-list.component.html',
  styles: [],
})
export class RecordingListComponent implements OnInit {
  public rooms: BbbRoom[] = [];
  public dayjs = dayjs;
  public modalRef: BsModalRef;
  private recIdToDelete: string;

  constructor(
    public authService: AuthService,
    public bbbService: BbbService,
    private modalService: BsModalService,
    public loginService: LoginService
  ) { }

  public async ngOnInit() {
    this.rooms = await this.bbbService.getAllRooms();
    await this.bbbService.getRecordings();
    await this.bbbService.getAllRecordings();
  }

  public isAdmin(): boolean {
    return this.loginService.loggedInUser?.permissions?.includes('admin');
  }

  public async openAftershowPartyModal(selectedRecord: Recording) {
    let recordings: Recording[];
    if (this.isAdmin()) {
      recordings = await this.bbbService.getAllRecordings();
    } else {
      recordings = await this.bbbService.getRecordings();
    }
    const record = recordings.find((r) => r._id == selectedRecord._id);
    const initialState = {
      record
    };
    this.modalRef = this.modalService.show(RecGuestListComponent, {
      initialState,
      class: 'modal-lg',
    });
  }

  public openModal(template: TemplateRef<BsModalRef>, recId: string) {
    this.recIdToDelete = recId;
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }

  public async confirm() {
    this.modalRef.hide();
    this.bbbService.deleteRecording(this.recIdToDelete);
    this.recIdToDelete = null;
    await this.bbbService.getRecordings();
    await this.bbbService.getAllRecordings();
  }

  public decline(): void {
    this.modalRef.hide();
    this.recIdToDelete = null;
  }

  public async changeDeleteOption(record: Recording) {
    if (this.checkExpireDate(record.uploadDate)) {
      await this.bbbService.changeRecDeleteOption(record._id, !record.metadata.autoDelete);
      await this.bbbService.getRecordings();
      await this.bbbService.getAllRecordings();
    }
  }

  public confirmCopy(e: MouseEvent): void {
    (e.currentTarget as HTMLElement).className = 'ri-checkbox-circle-fill text-success cursor-pointer';
    setTimeout(this.confirmCopyDone.bind(this, e.currentTarget), 500);
  }

  public confirmCopyDone(t: { className: string }) {
    t.className = 'ri-clipboard-line cursor-pointer';
  }

  public copyGlobalLink(recId: string) {
    navigator.clipboard.writeText(`${window.location.origin}/playback/global/${this.bbbService.getShort()}/${recId}`);
  }

  public checkExpireDate(date: Date) {
    return dayjs(date).add(2, "week").isAfter(dayjs());
  }
}
