<!-- LOGO -->
<div class="w-50 m-auto">
  <div class="m-auto py-5" style="max-width: 350px">
    <app-rag-logo></app-rag-logo>
  </div>
</div>
<!-- CARD -->
<div class="card m-auto" style="max-width: 450px">
  <div class="card-body">
    <!-- title -->
    <h4 class="text-center my-5">{{ this.roomName }}</h4>
    <div class="w-50 m-auto"></div>
    <div
      *ngIf="infoText"
      class="alert alert-info text-center"
      [innerHtml]="bbparse(infoText)"
    ></div>
    <div
      *ngIf="warnText"
      class="alert alert-warning text-center"
      [innerHtml]="bbparse(warnText)"
    ></div>
    <div
      *ngIf="dangerText"
      class="alert alert-danger text-center"
      [innerHtml]="bbparse(dangerText)"
    ></div>
    <ng-content></ng-content>
  </div>
</div>
