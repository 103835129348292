import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-joinpagebutton',
  templateUrl: './joinpagebutton.component.html',
  styles: [],
})
export class JoinpagebuttonComponent implements OnInit {
  @Input() public autoClickDelay: number;
  @Input() public disabled: boolean = false;
  @Output() public btnClick: EventEmitter<null> = new EventEmitter();
  @Input() public type: string = "primary";

  public ngOnInit(): void {
    if (this.autoClickDelay) {
      setTimeout(() => {
        this.btnClick.emit();
      }, this.autoClickDelay);
    }
  }
}
