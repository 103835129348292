import { Injectable } from '@angular/core';

import { BbbService } from '../bbb.service';

@Injectable()
export class BbbListResolver {
  constructor(private service: BbbService) { }

  public async resolve() {
    const rooms = await this.service.getAllRooms();
    return { rooms };
  }
}
