<app-form-grop>
  <ng-container label>Teilnehmerbegrenzung</ng-container>
  <input
    input
    type="number"
    class="form-control"
    [(ngModel)]="this.room.maxParticipants"
    min="-1"
  />
  <small class="form-text text-muted"
    >Legt eine Begrenzung der Teilnehmerzahl für diesen Raum fest.<br />
    -1 bedeutet, dass es keine Begrenzung gibt.</small
  >
</app-form-grop>
