import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  public isDevelopHost() {
    return (
      window.location.host.startsWith('develop') ||
      window.location.hostname === 'localhost'
    );
  }
}
