<app-layout>
  <div class="container mt-4">
    <app-card *ngIf="this.config">
      <div class="row mb-2">
        <div class="col">
          <b>Design</b>
        </div>
      </div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item px-0">
          <div class="row">
            <div class="col-3">BBB Akzentfarbe</div>
            <div class="col ms-auto">
              <input
                class="form-control form-control-color p-0"
                type="color"
                [(ngModel)]="this.config.primaryColor"
              />
            </div>
          </div>
        </li>
        <li class="list-group-item px-0">
          <div class="row">
            <div class="col-3">Sidekick Akzentfarbe</div>
            <div class="col ms-auto">
              <input
                class="form-control form-control-color p-0"
                type="color"
                [(ngModel)]="this.config.sidekickColor"
              />
            </div>
          </div>
        </li>

        <li class="list-group-item px-0">
          <div class="row">
            <div class="col-3">Willkommensnachricht</div>
            <div class="col ms-auto">
              <input
                class="form-control"
                type="text"
                [(ngModel)]="this.config.welcomeMessage"
              />
            </div>
          </div>
        </li>

        <li class="list-group-item px-0">
          <div class="row">
            <div class="col-3">Logo (318x70)</div>
            <div class="col ms-auto w-100">
              <img
                alt="logo"
                style="max-height: 2rem"
                [src]="this.logoUrl"
              />

              <div class="form-control mt-2">
                <input
                  #logoUpload
                  input
                  type="file"
                />
              </div>
            </div>
          </div>
        </li>
        <div class="row mb-2 mt-2">
          <div class="col">
            <b>Verhalten</b>
          </div>
        </div>
        <li class="list-group-item px-0">
          <div class="row">
            <div class="col-3">Beim Beitritt Stummschalten</div>
            <div class="col ms-auto">
              <input
                type="checkbox"
                [(ngModel)]="this.config.muteOnStart"
              />
            </div>
          </div>
        </li>

        <li class="list-group-item px-0">
          <div class="row">
            <div class="col-3">Webcams nur für Moderatoren</div>
            <div class="col ms-auto">
              <input
                type="checkbox"
                [(ngModel)]="this.config.webcamsOnlyForModerators"
              />
            </div>
          </div>
        </li>

        <div class="row mb-2 mt-2">
          <div class="col">
            <b>Weitere Systemeinstellungen</b>
          </div>
        </div>
        <li class="list-group-item px-0">
          <div class="row">
            <div class="col-3">TAN-Liste für Benutzer</div>
            <div class="col ms-auto">
              <input
                type="checkbox"
                [(ngModel)]="this.config.tanList"
              />
            </div>
          </div>
        </li>

        <p style="height: 5px"></p>
      </ul>
      <button
        class="btn btn-sm btn-primary me-1 mt-1"
        (click)="this.updateMandantConf()"
      >
        Speichern
      </button><button
        class="btn btn-sm text-primary me-1 mt-1"
        (click)="resetMandantConf()"
      >
        Einstellungen zurücksetzen
      </button>
    </app-card>
  </div>
</app-layout>