import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BBBUser } from '@reflact/kick';
import { RagDatasupplyFrontendService, RagDatasupplyTableQuicklookAsideComponent } from '@reflact/rag-datasupply';
import { removeFromArray } from '@reflact/tsutil';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from '../shared/AuthService';
import { UserService } from './user.service';

@Component({
  selector: 'app-user-admin',
  templateUrl: './user-admin.component.html',
  styles: [],
})
export class UserAdminComponent {
  @ViewChild("table") private table: RagDatasupplyTableQuicklookAsideComponent<BBBUser>
  public users: BBBUser[] = [];
  public modalRef?: BsModalRef;
  public newUser: { name: string; email: string; isAdmin: boolean } = {
    name: '',
    email: '',
    isAdmin: false,
  };
  public inviteOnCreate: boolean = false;

  constructor(
    public authService: AuthService,
    public frontendService: RagDatasupplyFrontendService<BBBUser>,
    public userService: UserService,
    public modalService: BsModalService,
    public aRoute: ActivatedRoute,
  ) {
    aRoute.data.subscribe((d) => {
      this.users = d.users;
      this.frontendService.fromArray(this.users);
    });
  }

  public async inviteUser(id) {
    await this.userService.inviteUser(id);
    this.modalRef.hide();
  }

  public async addUser() {
    const permissions = [];
    if (this.newUser.isAdmin) {
      permissions.push('admin');
    }
    const newUser = await this.userService.addUser({
      name: this.newUser.name,
      email: this.newUser.email,
      permissions,
    });
    if (this.inviteOnCreate) {
      await this.inviteUser(newUser._id);
    }
    this.users.push(newUser);
    this.newUser = { name: '', email: '', isAdmin: false };
  }
  public async editUser(
    _id: string,
    editedUser: { name?: string; email?: string; isAdmin?: boolean },
  ) {
    const permissions = this.users.find((u) => u._id).permissions;
    if (editedUser.isAdmin != null) {
      if (editedUser.isAdmin === true) {
        permissions.push('admin');
        this.table.selectedObject.permissions.push('admin');

      }
      if (editedUser.isAdmin === false) {
        removeFromArray(permissions, (p) => { return p == 'admin' });
        removeFromArray(this.table.selectedObject.permissions, (p) => { return p == 'admin' });
      }
    }


    delete editedUser.isAdmin;
    await this.userService.editUser(_id, {
      name: editedUser.name,
      email: editedUser.email,
      permissions,
    });
  }

  public async deleteUser(id: string) {
    await this.userService.deleteUser(id);
    this.users = await this.userService.getAllPortalUser()
    this.table.selectedObject = undefined;
    this.frontendService.fromArray(this.users);
    this.modalRef.hide()
  }
}
