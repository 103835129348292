import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './AuthService';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(private service: AuthService, private router: Router) { }

  public canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const user = this.service.getUserObject();
    const canActivate = user !== null;
    if (!canActivate) {
      this.router.navigate(['/', 'login']);
    }
    return canActivate;
  }
}