import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { License, MandantConfigCollectionLicense, SidekickServerConf } from '@reflact/kick';
import { firstValueFrom } from 'rxjs';


export type LicenseObject = {
  _id: string;
  type: 'kicklicense';
  license: License;
};

@Injectable({
  providedIn: 'root'
})
export class LicenseService {

  constructor(private http: HttpClient) { }
  public async getAllLicenses(): Promise<LicenseObject[]> {
    const url = '/api/mandantconfig/';
    const result: LicenseObject[] = await firstValueFrom(this.http.get<LicenseObject[]>(url));
    return result.filter(r => r.type === 'kicklicense');
  }

  public async getKickServers(): Promise<SidekickServerConf[]> {
    return await firstValueFrom(this.http.get<SidekickServerConf[]>(`/api/globaladmin/sidekickserverconf/list`));
  }

  public async createKickServer(creationData: Omit<SidekickServerConf, `_id`>): Promise<SidekickServerConf> {
    return await firstValueFrom(this.http.post<SidekickServerConf>(`/api/globaladmin/sidekickserverconf/create`, creationData));
  }

  public async updateKickServer(id: string, updateData: Partial<SidekickServerConf>): Promise<SidekickServerConf> {
    delete updateData._id;
    return await firstValueFrom(this.http.post<SidekickServerConf>(`/api/globaladmin/sidekickserverconf/update/` + id, updateData));
  }

  public async deleteKickServer(id: string): Promise<unknown> {
    return await firstValueFrom(this.http.get<unknown>(`/api/globaladmin/sidekickserverconf/delete/` + id));
  }


  public async getKickLicenses(short: string): Promise<MandantConfigCollectionLicense[]> {
    return await firstValueFrom(this.http.get<MandantConfigCollectionLicense[]>(`/api/globaladmin/license/list/` + short));
  }

  public async createKickLicense(short: string, creationData: Omit<MandantConfigCollectionLicense, `_id`>): Promise<MandantConfigCollectionLicense> {
    return await firstValueFrom(this.http.post<MandantConfigCollectionLicense>(`/api/globaladmin/license/create/` + short, creationData));
  }

  public async updateKickLicense(short: string, id: string, updateData: Partial<Omit<MandantConfigCollectionLicense, `_id`>>): Promise<MandantConfigCollectionLicense> {
    return await firstValueFrom(this.http.post<MandantConfigCollectionLicense>(`/api/globaladmin/license/update/` + short + `/` + id, updateData));
  }

  public async deleteKickLicense(short: string, id: string): Promise<string> {
    return await firstValueFrom(this.http.get<string>(`/api/globaladmin/license/delete/` + short + `/` + id));
  }


  public async getMandantList(): Promise<string[]> {
    return await firstValueFrom(this.http.get<string[]>("/api/globaladmin/mandant/list"))
  }

  public async createMandant(short: string, email: string): Promise<string> {
    return await firstValueFrom(this.http.get<string>(`/api/globaladmin/mandant/create/` + short + `/` + email))
  }
}
