import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToTime',
  standalone: true
})
export class SecondsToTimePipe implements PipeTransform {

  constructor() { }

  public transform(seconds: number): string {
    const hours = Math.floor(seconds / (60 * 60));
    const mins = Math.floor((seconds % (60 * 60)) / 60);
    const secs = seconds % 60;
    return `${hours > 0 ? hours + ':' : ''}${mins}:${this.fD(Math.floor(secs))}`;
  }

  //? Fix Digits
  private fD(n: number): string {
    return n > 9 ? "" + n : "0" + n;
  }


}
