import { AfterViewInit, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BbbService } from '../bbb.service';
import { AuthService } from '../shared/AuthService';

@Component({
  templateUrl: './record-video-playback.component.html',
  styles: ``
})
export class RecordVideoPlaybackComponent implements AfterViewInit {
  @ViewChild('videoPlayer') public videoPlayer: ElementRef<HTMLVideoElement>;

  public input: string;
  public short: string;
  public recordId: string;
  public guestId: string;
  public currentTime: number = 0;
  public durationTime: number = 0;
  public videoUrl: string = '';
  public passwordInput: string = '';
  public passwordStatus: "valid" | "invalid" | "notentered" = "notentered";
  public videoEnded: boolean = false;
  public videoPaused: boolean = false;

  @HostListener('window:beforeunload', ['$event'])
  protected showMessage($event) {
    if (this.videoEnded == false && this.input == 'play' && this.passwordStatus == "valid") {
      $event.returnValue = "Sind Sie sicher, dass sie die Seite verlassen wollen? Ihre Aufzeichung wurde noch nicht bis zum Ende angesehen! Der Status wurde noch nicht gespeichert!";
    } else {
      return true;
    }
  }

  constructor(private route: ActivatedRoute, public authService: AuthService, private bbbService: BbbService) {
    this.route.params.subscribe((data) => {
      this.input = data.input;
      this.short = data.short;
      this.recordId = data.id;
      if (this.input == 'play') {
        this.guestId = data.guest;
      }
      if (this.input == 'global' && data.guest == undefined) {
        this.passwordStatus = "valid";
        this.videoUrl = '/api/playrecording/' + this.recordId + '/' + this.short;
      }
    });
  }

  public async checkPw() {
    try {
      const res = await this.bbbService.checkRecGuestLogin(this.recordId, this.guestId, this.passwordInput, this.short);
      if (res.status == "ok") {
        this.passwordStatus = "valid";
        this.videoUrl = '/api/playrecording/' + this.recordId + '/' + this.short + '/' + this.guestId + '/' + encodeURIComponent(this.passwordInput);
        this.videoPlayer.nativeElement.load();
      }
    } catch (e) {
      this.passwordStatus = "invalid";
    }
  }

  public ngAfterViewInit(): void {
    this.videoPlayer.nativeElement.addEventListener('play', () => {
      this.videoPlayer.nativeElement.addEventListener('timeupdate', () => {
        this.currentTime = this.videoPlayer.nativeElement.currentTime;
      });
    });
    this.videoPlayer.nativeElement.addEventListener('loadedmetadata', () => {
      this.durationTime = this.videoPlayer.nativeElement.duration;
    });
    this.videoPlayer.nativeElement.addEventListener('ended', () => {
      this.videoEnded = true;
      this.bbbService.sendRecordingSuccess(this.recordId, this.guestId, this.passwordInput, this.short);
    })
  }
}