<fieldset class="form-group">
  <div class="row">
    <legend class="col-form-label col-sm-3 p-0 font-weight-bold text-right">
      Moderatoren-URL
    </legend>
    <div class="col-sm-9">
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="moderatorInvitationKeyActive"
          id="moderatorInvitationKeyActive1"
          [value]="false"
          [checked]="!this.room.moderatorInvitationKeyActive"
          [(ngModel)]="this.room.moderatorInvitationKeyActive"
        />
        <label
          class="form-check-label"
          for="moderatorInvitationKeyActive1"
        >
          Inaktiv
        </label>
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="moderatorInvitationKeyActive"
          id="moderatorInvitationKeyActive2"
          [value]="true"
          [checked]="this.room.moderatorInvitationKeyActive"
          (ngModelChange)="onModeratorInvitationKeyActive()"
          [(ngModel)]="this.room.moderatorInvitationKeyActive"
        />
        <label
          class="form-check-label"
          for="moderatorInvitationKeyActive2"
        >
          Aktiv
        </label>
      </div>
      <input
        class="form-control"
        *ngIf="this.room.moderatorInvitationKeyActive"
        readonly
        value="{{ window.location.href }}join/{{
          this.room.moderatorInvitationKey
        }}/{{ this.bbbService.getShort() }}"
      />
      <small class="text-muted form-text">Wenn aktiviert, wird eine Moderator-URL generiert, die den Zugriff mit
        Moderatorberechtigung ermöglicht.</small>
    </div>
  </div>
</fieldset>