import { Injectable } from '@angular/core';
import { LicenseObject, LicenseService } from '../license-management/license.service';

@Injectable()
export class LicenseResolver {
  constructor(private service: LicenseService) { }

  public async resolve() {
    const licenses: LicenseObject[] = await this.service.getAllLicenses();
    return licenses;
  }
}
