import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { BbbService } from '../bbb.service';

@Component({
  selector: 'app-bbb-room-createform',
  templateUrl: './bbb-room-createform.component.html',
  styleUrls: ['./bbb-room-createform.component.scss'],
})
export class BbbRoomCreateformComponent {

  public newRoomName = "";

  constructor(public service: BbbService) { }

  @Input() public techcheck = false;
  @ViewChild('newRoomInput') public newRoomInput: ElementRef;
  public roomNameIsValid = true;
  public async create() {
    this.newRoomName = this.newRoomName.trim();
    if (this.newRoomName.length === 0) {
      this.roomNameIsValid = false;
      return;
    }
    this.roomNameIsValid = true;
    const result = await this.service.createRoom(this.newRoomName);
    if (result) {
      this.newRoomName = ""
    }
  }
}
