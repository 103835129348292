import { Component, Input, OnInit } from '@angular/core';
import { BbbRoom, BBBUser } from '@reflact/kick';
import { BbbService } from 'src/app/bbb.service';
import { UserService } from 'src/app/user-admin/user.service';

@Component({
  selector: 'app-shared-users-editor',
  templateUrl: './shared-users-editor.component.html',
  styles: []
})
export class SharedUsersEditorComponent implements OnInit {
  @Input() public room: BbbRoom;
  public portalUsers: Promise<BBBUser[]>;
  constructor(public bbbService: BbbService, private userService: UserService) { }

  public ngOnInit(): void {
    if (!this.room.editors) {
      this.room.editors = [];
    }
    this.portalUsers = this.userService.getPartialPortalUsers([
      this.room.creator,
    ]);
  }

  public onEditorsChanged() {
    this.bbbService.updateRoom(this.room);
  }
}
