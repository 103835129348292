<app-layout>
  <div class="container mt-4">
    <app-card>
      <div class="row mb-2">
        <div class="col">
          <b>Meine Lizenzen</b>
        </div>
      </div>
      <ul class="list-group list-group-flush">
        <li
          *ngFor="let licenseObj of licenses"
          class="list-group-item px-0"
        >
          <div class="row">
            <div class="col-auto">
              {{
              licenseObj.license.type === "named"
              ? "Named Host"
              : "Concurrent User"
              }}
              Lizenz ({{ licenseObj.license.count }} Benutzer)
            </div>
          </div>
        </li>
      </ul>
      <p style="height: 10px"></p>
      <ng-container *ngIf="namedHostSize > 0">
        <div class="row mb-2">
          <div class="col-auto">
            <b>Benannte Veranstalter</b>
          </div>
          <div class="col-auto ms-auto">
            <small>{{ namedHostIds.length }} / {{ namedHostSize }}</small>
          </div>
        </div>
        <ng-select
          [items]="users"
          bindLabel="name"
          [multiple]="true"
          [maxSelectedItems]="namedHostSize"
          bindValue="_id"
          [(ngModel)]="namedHostIds"
          (add)="editUserForNamedHost($event)"
          (remove)="editUserForNamedHost($event.value)"
        >
        </ng-select>
      </ng-container>
    </app-card>
  </div>
</app-layout>