<div class="fakebody row p-0 m-0 align-items-center">
  <div class="col p-0 m-0">
    <div class="box m-auto overflow-auto">
      <form (submit)="login()">
        <h2 class="text-center">Login</h2>

        <p
          *ngIf="this.message"
          class="alert alert-info"
        >
          {{this.message}}
        </p>
        <ng-container *ngIf="state === 'email'">
          <div class="inputBox">
            <input
              required="required"
              autocomplete="email"
              type="email"
              id="email"
              name="email"
              [(ngModel)]="email"
            >
            <span>Email Adresse</span>
            <i></i>
          </div>
        </ng-container>
        <ng-container *ngIf="state === 'code'">
          <div>Bitte geben Sie den Code aus Ihrer E-Mail oder Ihrer Authentifizierungs-App ein</div>
          <div class="inputBox">
            <input
              required="required"
              autocomplete="password"
              type="password"
              id="password"
              name="password"
              [(ngModel)]="code"
            >
            <span>Code</span>
            <i></i>
          </div>
          <div
            class="mt-3"
            *ngIf="tanActive === true"
          >Alternativ können Sie eine Anmeldung per TAN-Nummer durchführen</div>
        </ng-container>
        <ng-container *ngIf="state === 'tan' && tanActive === true">
          <div>Bitte geben Sie die <strong>TAN-Nummer {{ tanNumber }}</strong> aus Ihrer TAN-Liste ein</div>
          <div class="inputBox">
            <input
              required="required"
              autocomplete="password"
              type="password"
              id="password"
              name="password"
              [(ngModel)]="tan"
            >
            <span>TAN</span>
            <i></i>
          </div>
          <div class="mt-3">Mit dem Zurück-Button gelangen Sie wieder zur Eingabe des per Email versendeten Code.</div>
          <div>Durch diese Aktion verliert die angeforderte <strong>TAN-Nummer {{ tanNumber }} ihre Gültigkeit</strong>
          </div>
        </ng-container>
        <ng-container *ngIf="state === 'mandant'">
          <div
            class="mandant ro"
            *ngFor="let mandant of mandants"
            (click)="this.selectMandant(mandant)"
          >
            <div class="d-flex align-items-center px-3"><i class="ri-3x ri-building-line"></i><b
                class="ms-2">{{mandant}}</b></div>
          </div>
        </ng-container>
        <div>
          <ng-container *ngIf="state === 'tan' && tanActive === true">
            <div class="text-center mt-3 d-inline-block me-3">
              <input
                *ngIf="state === 'tan'"
                type="button"
                class="btn btn-outline-primary"
                style="width: 100px;"
                value="Zurück"
                (click)="backToCode()"
              >
            </div>
          </ng-container>
          <ng-container *ngIf="state === 'code' && tanActive === true">
            <div class="text-center mt-3 d-inline-block me-3">
              <input
                *ngIf="state === 'code'"
                type="button"
                class="btn btn-outline-primary"
                style="width: 100px;"
                value="TAN-Eingabe"
                (click)="getTanNumber()"
              >
            </div>
          </ng-container>
          <div class="text-center mt-3 d-inline-block">
            <input
              *ngIf="state !== 'mandant'"
              type="submit"
              class="btn btn-outline-primary"
              style="width: 100px;"
              value="Weiter"
            >
          </div>
        </div>
      </form>
    </div>
  </div>
</div>