import { Injectable } from '@angular/core';
import { BBBUser } from '@reflact/kick';
import { UserService } from '../user-admin/user.service';

@Injectable()
export class UserResolver {
  constructor(private service: UserService) { }

  public async resolve() {
    const users: BBBUser[] = await this.service.getAllPortalUser();
    return users;
  }
}
