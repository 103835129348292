import { Component, Input, TemplateRef } from '@angular/core';
import { BbbRoom } from '@reflact/kick';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BbbService } from '../bbb.service';

@Component({
  selector: 'app-bbb-room-leavebutton',
  templateUrl: './bbb-room-leavebutton.component.html',
  styles: [],
})
export class BbbRoomLeavebuttonComponent {
  public modalRef: BsModalRef;
  @Input() public room: BbbRoom;
  constructor(
    private modalService: BsModalService,
    private service: BbbService,
  ) { }

  public openModal(template: TemplateRef<BsModalRef>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
  }

  public async confirm(): Promise<void> {
    this.modalRef.hide();
    const index = this.room.editors.indexOf(
      this.service.getUserObject()._id,
    );
    if (index == -1) return;
    this.room.editors.splice(index, 1);
    const result = await this.service.updateRoom(this.room);
    const roomIndex = this.service.rooms.findIndex((r) => r._id === result._id);
    if (roomIndex == -1) return;
    this.service.rooms.splice(roomIndex, 1);
  }

  public decline(): void {
    this.modalRef.hide();
  }
}
