import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BbbRoom } from '@reflact/kick';
import { BbbService } from 'src/app/bbb.service';

@Component({
  selector: 'app-access-editor',
  templateUrl: './access-editor.component.html',
  styles: [],
})
export class AccessEditorComponent {
  @Input() public room: BbbRoom;
  @Output() public guestListClick: EventEmitter<boolean> = new EventEmitter();
  constructor(public service: BbbService) { }

  public originalOrder(): number {
    return 0;
  }
}
