import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-bbbframe',
  templateUrl: './bbbframe.component.html',
  styles: [],
})
export class BBBFrameComponent {
  public bbbFrameSrc;

  constructor(
    protected aRoute: ActivatedRoute,
    private dom: DomSanitizer,
  ) {
    this.bbbFrameSrc = this.dom.bypassSecurityTrustResourceUrl(
      this.aRoute.snapshot.paramMap.get('bbburl'),
    );
  }
}
