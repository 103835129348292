import { Component } from '@angular/core';

@Component({
  templateUrl: './license-management.component.html',
  styles: [
  ]
})
export class LicenseManagementComponent {

}
