import { Component } from '@angular/core';
import { BbbService } from '../bbb.service';
import { AuthService } from '../shared/AuthService';
import { UseradminGuard } from '../shared/useradmin.guard';

@Component({
  selector: 'app-page-bbb-rooms',
  templateUrl: './page-bbb-rooms.component.html',
  styleUrls: ['./page-bbb-rooms.component.scss'],
})
export class PageBbbRoomsComponent {
  constructor(
    public adminGuard: UseradminGuard,
    public authService: AuthService,
    public bbbService: BbbService,
  ) {}
}
