import { Component, Input, OnInit } from '@angular/core';
import { BBBUser, BbbRoom } from '@reflact/kick';
import { AuthService } from 'src/app/shared/AuthService';

@Component({
  selector: 'app-welcome-editor',
  templateUrl: './welcome-editor.component.html',
})
export class WelcomeEditorComponent implements OnInit {
  @Input() public room: BbbRoom;
  public user: BBBUser;
  constructor(public authService: AuthService) { }

  public ngOnInit(): void {
    this.user = this.authService.getUserObject();
  }
}