import { Component, Input, OnInit } from '@angular/core';
import {
  BbbRoom,
  FsPortalFile,
  PresentationFileMetadata,
} from '@reflact/kick';
import { UploadState, UploadxOptions } from 'ngx-uploadx';
import { BbbService } from 'src/app/bbb.service';
import { Ufile } from 'src/app/shared/ufile';

@Component({
  selector: 'app-presentation-editor',
  templateUrl: './presentation-editor.component.html',
  styles: [],
})
export class PresentationEditorComponent implements OnInit {
  @Input() public room: BbbRoom;
  public presentations: FsPortalFile<PresentationFileMetadata>[];
  public uploads: Ufile[] = [];
  public uploadxOptions: UploadxOptions = {
    allowedTypes: 'application/pdf',
    concurrency: 2,
    headers: { token: localStorage.getItem('bbbJWT') },
  };
  constructor(public service: BbbService) { }

  public async ngOnInit() {
    this.uploadxOptions.metadata = {
      roomId: this.room._id,
      token: localStorage.getItem('bbbJWT'),
    };
    this.uploadxOptions.endpoint = '/api/upload/presentation/';
    await this.loadPresentations();
  }

  public async deletePresentation(presentationId: string) {
    await this.service.deletePresentation(presentationId);
    const index = this.presentations.findIndex(
      (presentation) => presentation._id === presentationId,
    );
    this.presentations.splice(index, 1);
  }

  public onUpload(state: UploadState) {
    const file = this.uploads.find((item) => item.uploadId === state.uploadId);
    if (state.status === 'complete') {
      this.loadPresentations();
      this.uploads.splice(
        this.uploads.findIndex((item) => item.uploadId === state.uploadId),
        1,
      );
    } else {
      file ? file.update(state) : this.uploads.push(new Ufile(state));
    }
  }

  private async loadPresentations() {
    this.presentations = await this.service.getPresentations(this.room._id);
  }
}