import { Component, Input } from '@angular/core';
import { BbbRoom } from '@reflact/kick';

@Component({
  selector: 'app-name-editor',
  templateUrl: './name-editor.component.html',
  styles: [],
})
export class NameEditorComponent {
  @Input() public room: BbbRoom;
}
