import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-joincard',
  templateUrl: './joincard.component.html',
  styles: [],
})
export class JoincardComponent {
  @Input() public roomName = '';
  @Input() public infoText = '';
  @Input() public warnText = '';
  @Input() public dangerText = '';

  public bbparse(text: string) {
    let o = text.split('[b]').join('<b>');
    o = o.split('[/b]').join('</b>');
    o = o.split('[br]').join('<br>');
    return o;
  }
}
