import { Component, Input } from '@angular/core';
import { BbbRoom } from '@reflact/kick';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BbbRoomEditFormComponent } from '../bbb-room-edit-form/bbb-room-edit-form.component';
import { BbbService } from '../bbb.service';

@Component({
  selector: 'app-bbb-room-edit-button',
  templateUrl: './bbb-room-edit-button.component.html',
  styleUrls: ['./bbb-room-edit-button.component.scss'],
})
export class BbbRoomEditButtonComponent {
  @Input() public room: BbbRoom;
  constructor(
    private modalService: BsModalService,
    private service: BbbService,
  ) { }
  public modalRef: BsModalRef;

  public openModal() {
    const initialState = {
      roomId: this.room._id,
    };
    this.modalRef = this.modalService.show(BbbRoomEditFormComponent, {
      initialState,
      class: 'modal-lg',
    });
  }

  public confirm(): void {
    this.modalRef.hide();
    this.service.deleteRoom(this.room._id);
  }

  public decline(): void {
    this.modalRef.hide();
  }
}
