import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BBBUser } from '@reflact/kick';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private router: Router,
  ) { }
  public logout(): void {
    localStorage.removeItem('bbbJWT');
    window.location.href = '/login';
  }
  public getUserObject(short?: string): BBBUser | null {
    try {
      const portalJWT = localStorage.getItem('bbbJWT')
      if (portalJWT == null) {
        return null;
      }
      const result: BBBUser & { exp: number } = jwtDecode(portalJWT);
      if (result != null) {
        if (short != null && result.short != short) {
          return null
        }
        const x = new Date();
        if (result.exp >= x.getTime() / 1000) {
          return result;
        } else if (result.exp < x.getTime() / 1000) {
          localStorage.removeItem('bbbJWT');
        }


      }
    } catch (e) {
      return null;
    }
    return null;
  }

  public navigate() {
    this.router.createUrlTree(['/']);
  }
  public getToken() {
    return localStorage.getItem('bbbJWT');
  }
}
