import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TokenGuard {
  constructor(private router: Router, private http: HttpClient) { }
  public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const name = route.params.name;
    const email = route.params.email;
    if (name !== null && email !== null) {
      const url = '/api/demo/login/' + name + '/' + email;
      const obj: { token: string } = await firstValueFrom(this.http.get<{ token: string }>(url));
      localStorage.setItem('bbbJWT', obj.token);
      this.router.navigate([]);
    }
    return true;
  }
}
