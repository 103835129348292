<ng-container *ngIf="this.mode === 'edit'">
  <div class="row">
    <div class="col-4">
      <input
        #search
        [(ngModel)]="searchString"
        (keyup)="filterChange()"
        class="form-control"
        placeholder="Suche..."
      >
    </div>
    <div class="col"></div>
    <div class="col-auto">
      <div class="btn-group">
        <div
          class="btn btn-outline-primary"
          (click)="addToGuestList()"
        >
          <i class="ri-user-add-fill"></i> Person hinzufügen
        </div>
        <div
          class="btn btn-outline-primary"
          (click)="this.mode = 'input'"
        >
          <i class="ri-group-fill"></i> Importieren
        </div>
        <div
          class="btn btn-outline-primary"
          (click)="exportGuestList()"
        >
          <i class="ri-download-line"></i> Exportieren
        </div>
      </div>
    </div>
  </div>
  <div
    class="row justify-content-end mt-1"
    *ngIf="this.linkOption === 'recording' && this.hasRecordAValidRoom"
  >
    <div class="col-auto">
      <div
        class="btn btn-outline-primary"
        (click)="copyListFromRoomConfig()"
      >
        <i class="ri-file-copy-fill"></i> Teilnehmer von Raum-Liste kopieren
      </div>
    </div>
  </div>
  <hr>
  <div
    class="row align-items-center"
    *ngIf="this.shownGuests.length > 0"
  >
    <div class="col-auto">
      <span class="me-2">
        <input
          [ngModel]="selectedIncludesAllShown()"
          (ngModelChange)="changeAllSelected($event)"
          type="checkbox"
        >
      </span>
      <b>{{this.selectedGuests.length}}/{{this.list.length}} selektiert</b>
    </div>
    <div class="col-auto">
      <div
        class="btn btn-outline-danger btn-sm"
        (click)="confirmDelete(confirmModal)"
        [ngClass]="{'disabled': this.list.length === 0 || this.selectedGuests.length === 0}"
      >Ausgewählte löschen</div>
    </div>
    <div class="col"></div>
  </div>
  <div
    class="row align-items-center my-2"
    *ngFor="let guest of shownGuests"
  >
    <div class="col-auto">
      <input
        type="checkbox"
        [ngModel]="this.selectedGuests.includes(guest.id)"
        (change)="changeSelection(guest.id)"
      >
    </div>
    <div class="col-auto"><i
        class="ri-user-fill ri-lg"
        style="cursor: default;"
      ></i></div>
    <div class="col-3"><input
        class="form-control"
        [(ngModel)]="guest.name"
        (blur)="this.updateSingleGuest(guest)"
      ></div>
    <div class="col-3">
      <div class="input-group">
        <input
          #input
          class="form-control"
          type="password"
          [(ngModel)]="guest.password"
          (blur)="this.updateSingleGuest(guest)"
        >
        <div
          class="btn btn-outline-primary"
          (click)="input.type = input.type === 'password' ? 'text' : 'password'"
        >
          <i [ngClass]="{'ri-eye-line': input.type !== 'password', 'ri-eye-close-line': input.type === 'password'}"></i>
        </div>
      </div>
    </div>
    <div class="col cut-text"><input
        [ngModel]="window.location.origin + guest.link"
        [disabled]="true"
        class="form-control"
      >
    </div>
    <div
      class="col-auto"
      *ngIf="this.record"
    >
      <span *ngIf="this.guest.hasWatchedRecording === undefined">
        <i
          class="ri-check-line ri-2x text-secondary"
          placement="top"
          tooltip="Aufzeichnungslink nicht aufgerufen"
        ></i>
      </span>
      <span *ngIf="this.guest.hasWatchedRecording === false">
        <i
          class="ri-check-line ri-2x text-warning"
          placement="top"
          tooltip="Aufzeichnungslink aufgerufen"
        ></i>
      </span>
      <span *ngIf="this.guest.hasWatchedRecording === true">
        <i
          class="ri-check-line ri-2x text-success"
          placement="top"
          tooltip="Aufzeichnung vollständig gesichtet"
        ></i>
      </span>
    </div>
    <div class="col-auto text-danger">
      <i
        class="ri-delete-bin-line ri-lg"
        (click)="this.selectedGuests = [guest.id]; confirmDelete(confirmModal)"
      ></i>
    </div>
  </div>
</ng-container>

<div *ngIf="this.mode === 'input'">
  <div class="row">
    <div class="col-auto">
      <div
        class="btn btn-light"
        (click)="this.mode = 'edit'"
      >
        <i class="ri-arrow-left-line"></i>Zurück
      </div>
    </div>
    <div class="col"></div>
    <div class="col-auto">
      <a
        class="btn btn-outline-primary"
        #demoDownload
        href="assets/Demo-Guestlist-Import.xlsx"
        download="Demo-Guestlist-Import.xlsx"
      ><i class="ri-download-line"></i>Demo-Datei</a>
    </div>
  </div>
  <div class="text-center">
    <input
      #importInput
      type="file"
      class="visually-hidden"
      id="excelInput"
      name="excelInput"
      accept=".xls, .xlsx"
      (change)="onExcelInput($event)"
    >
    <div
      *ngIf="this.importInput.value === ''"
      class="btn btn-primary"
      (click)="importInput.click()"
    >Datei auswählen</div>
    <div
      *ngIf="this.importInput.value !== ''"
      class="btn btn-primary"
      (click)="importGuestList()"
    >Gäste importieren</div>
  </div>
  <hr>
  <app-card>
    <lib-rag-datasupply-table
      *ngIf="this.importInput.value !== ''"
      [service]="frontendService"
      [columns]="[
      {fieldName: 'name', label: 'Name'},
      {fieldName: 'password', label: 'Passwort'}
    ]"
      noDataText="Keine Gäste vorhanden."
      [overrideTableClasses]="''"
    ></lib-rag-datasupply-table>
  </app-card>
</div>


<ng-template #confirmModal>
  <div class="modal-header">
    <button
      type="button"
      class="btn-close close pull-right"
      aria-label="Close"
      (click)="modalRef?.hide()"
    >
      <span
        aria-hidden="true"
        class="visually-hidden"
      >&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Sind Sie sicher, dass die {{selectedGuests.length}} Gäste von der Liste entfernen wollen?

    <div class="text-center m-3">
      <div class="btn-group">
        <div
          class="btn btn-outline-secondary btn-sm"
          (click)="modalRef?.hide()"
        >Nein</div>
        <div
          class="btn btn-outline-danger btn-sm"
          (click)="deleteSelected(); modalRef?.hide()"
        >Ja</div>
      </div>
    </div>
  </div>
</ng-template>