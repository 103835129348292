import { Component } from '@angular/core';

@Component({
  selector: 'app-bbb-room-edit-advanced',
  templateUrl: './bbb-room-edit-advanced.component.html',
  styleUrls: ['./bbb-room-edit-advanced.component.scss'],
})
export class BbbRoomEditAdvancedComponent {
  public extended = false;
}
