import { Component, Input } from '@angular/core';
import { BbbRoom } from '@reflact/kick';

@Component({
  selector: 'app-max-participants-editor',
  templateUrl: './max-participants-editor.component.html',
})
export class MaxParticipantsEditorComponent {
  @Input() public room: BbbRoom;
}
