<div
  class="modal-body"
  [ngStyle]="{
    'background-color': service.roomIdToIsRunning.get(room._id)
      ? '#fff3cd'
      : '#fff'
  }"
  *ngIf="this.room"
>
  <div
    [ngStyle]="{
      'background-color': service.roomIdToIsRunning.get(room._id)
        ? '#fff3cd'
        : '#fff'
    }"
    class="p-4 pt-5"
  >
    <div *ngIf="service.roomIdToIsRunning.get(room._id)">
      <app-form-grop>
        <span label>Info</span>
        <div class="m-0 alert-warning">
          <b>Dieser Raum ist gerade aktiv</b> <br />
          <small>Konfigurationen werden erst nach dem Beenden übernommen</small>
          <br />
          <span
            (click)="service.endRoom(room._id)"
            class="btn-warning btn-sm btn cursor-pointer"
          >Raum jetzt beenden</span>
        </div>
      </app-form-grop>
      <hr />
    </div>


    <app-name-editor
      [room]="this.room"
      class="mb-2"
      (change)="this.updateRoom()"
    ></app-name-editor>
    <br>
    <app-url-editor [room]="this.room"></app-url-editor>
  </div>
  <div class="ms-n2">
    <tabset
      class="edit"
      [justified]="true"
      #staticTabs
    >
      <tab
        heading="Konfiguration"
        (change)="this.updateRoom()"
      >
        <div class="px-4 py-5 bordered">
          <app-welcome-editor [room]="this.room"></app-welcome-editor>
          <app-bbb-room-edit-advanced *ngIf="false">
            <app-max-participants-editor [room]="this.room"></app-max-participants-editor>
            <app-record-editor [room]="this.room"></app-record-editor>
          </app-bbb-room-edit-advanced>
        </div>
      </tab>
      <tab
        heading="Berechtigung"
        (change)="this.updateRoom()"
      >
        <div class="px-4 py-5 bordered">
          <app-access-editor
            [room]="this.room"
            (guestListClick)="this.selectTab(3)"
          ></app-access-editor>
          <app-moderator-invatation-key-editor [room]="this.room"></app-moderator-invatation-key-editor>
          <app-shared-users-editor [room]="this.room"></app-shared-users-editor>
        </div>
      </tab>
      <tab
        heading="Präsentationen"
        (change)="this.updateRoom()"
      >
        <div class="px-4 py-5 bordered">
          <app-presentation-editor [room]="room"></app-presentation-editor>
        </div>
      </tab>
      <tab
        heading="Gästeliste"
        *ngIf="this.room.access === 'password_individual'"
      >
        <div class="px-4 py-5 bordered">
          <app-guest-list-editor
            [list]="this.room.guestList"
            [roomId]="this.room._id"
            [roomName]="this.room.name"
            [linkOption]="'room'"
            (listChange)="this.updateList($event)"
          ></app-guest-list-editor>
        </div>
      </tab>
    </tabset>
  </div>
</div>