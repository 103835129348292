import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SidekickFile, SidekickFileService } from './../shared/sidekick-file.service';

@Component({
  templateUrl: './sidekick-file-share.component.html',
  styles: []
})
export class SidekickFileShareComponent {
  public files: SidekickFile[] = [];
  public sidekicktoken = "";

  constructor(
    public aRoute: ActivatedRoute,
    public sidekickFileService: SidekickFileService,
  ) {
    aRoute.data.subscribe((data) => {
      this.files = data.data.files;
      this.sidekicktoken = data.data.sidekicktoken;
    });
  }

  public getDownloadUrlForFile(file: SidekickFile) {
    const url = this.sidekickFileService.getDownloadurl(this.sidekicktoken, file._id);
    return url;
  }
}
