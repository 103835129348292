import { Component, OnInit, TemplateRef } from '@angular/core';
import { MandantConfigCollectionLicense } from '@reflact/kick';
import { RagDatasupplyFrontendService } from '@reflact/rag-datasupply';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LicenseService } from '../license.service';

@Component({
  selector: 'app-mandant-table',
  templateUrl: './mandant-table.component.html',
  styles: [
  ],
  providers: [RagDatasupplyFrontendService]
})
export class MandantTableComponent implements OnInit {

  public modalRef?: BsModalRef
  public mandants: { short: string }[] = []
  public shortToLicensesMap: Map<string, MandantConfigCollectionLicense[]> = new Map();
  constructor(
    public frontendService: RagDatasupplyFrontendService<{ short: string }>,
    public licenseService: LicenseService,
    public modalService: BsModalService
  ) { }

  public async ngOnInit(): Promise<void> {
    await this.loadMandants();
  }

  public openModal(template: TemplateRef<BsModalRef>, data: unknown) {
    this.modalRef = this.modalService.show(template, { class: 'modal-xl' });
    this.modalRef.content = { data }
  }

  public async createMandant(data: { short: string, email: string }) {
    await this.licenseService.createMandant(data.short, data.email);
    this.modalService.hide();
    this.modalRef = undefined;
    await this.loadMandants()
  }

  public async loadMandants() {
    this.mandants = (await this.licenseService.getMandantList()).map(m => ({ short: m }))
    this.frontendService.fromArray(this.mandants)
    for (const m of this.mandants) {
      const licenses = await this.licenseService.getKickLicenses(m.short);
      this.shortToLicensesMap.set(m.short, licenses);
    }
  }
}
