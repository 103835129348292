<rag-body-layout
  *ngIf="this.navItems.length > 0"
  [navItems]="this.navItems"
  [logoUrl]="'assets/logo.svg'"
  [enableThemeSwitcher]="false"
  [loggedInUser]="this.loggedInUser"
  [logoutFunction]="authService.logout.bind(this)"
  [userEditClick]="this.openSelfUserSettings.bind(this)"
  [hasNotifications]="tanNotify"
>
  <ng-content></ng-content>
</rag-body-layout>

<rag-overlay-aside #selfUserSettings>
  <div
    header
    *ngIf="selfUserSettings.selectedObject"
  >
    <h1>Profil</h1>
  </div>
  <div *ngIf="selfUserSettings.selectedObject">
    <rag-form
      [formFields]="formFields"
      [data]="selfUserSettings.selectedObject.data"
      [hideDeleteButton]="true"
      [hideSaveButton]="true"
    ></rag-form>
    <div>
      <h3 class="my-3">TAN</h3>
      <div *ngIf="tanCreated === null">
        Es wurde noch keine TAN-Liste erstellt/heruntergeladen.
      </div>
      <div
        *ngIf="tanCount < 6 && tanCreated !== null"
        class="text-danger"
      ><strong><i class="ri-notification-2-fill"></i> Bitte generieren Sie zeitnah eine neue TAN-Liste!</strong></div>
      <div *ngIf="tanCreated !== null">
        Sie haben aktuell noch {{ tanCount }} von 20 TANs gültig.
        <br>
        Die TAN-Liste wurde am {{ tanCreated | date: 'dd.MM.yyyy HH:mm' }} erstellt.
      </div>
      <div *ngIf="tanCreated !== null">
        Bei Generation einer neuen TAN-Liste verliert die aktuelle Liste ihre Gültigkeit.
      </div>
      <div
        class="btn btn-success my-3"
        (click)="createTanList()"
      ><i class="ri-shield-check-fill"></i> TAN-Liste generieren/herunterladen</div>
      <div
        *ngIf="tanCreated !== null"
        class="btn btn-danger ms-4 my-3"
        (click)="deleteTanList()"
      ><i class="ri-delete-bin-fill"></i> Bestehende TAN-Liste deaktivieren</div>

    </div>
    <ng-container *ngIf="mandants !== []">
      <h3 class="my-3">Mandantenauswahl</h3>
      <div
        class="mandant ro"
        *ngFor="let mandant of mandants"
        [ngClass]="currentMandant === mandant ? 'bg-primary' : ''"
        (click)="changeMandant(mandant); router.navigate(['/'])"
      >
        <div class="d-flex align-items-center px-3"><i class="ri-building-line ri-3x"></i><b
            class="ms-2">{{mandant}}</b>
        </div>
      </div>
    </ng-container>
  </div>
</rag-overlay-aside>