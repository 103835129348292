<fieldset class="form-group">
  <div class="row">
    <legend
      class="col-form-label col-sm-3 p-0 font-weight-bold text-right pe-3"
    >
      Aufnahmefunktion
    </legend>
    <div class="col-sm-9">
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="record"
          id="record1"
          [value]="true"
          [checked]="this.room.record"
          [(ngModel)]="this.room.record"
        />
        <label class="form-check-label" for="record1"> Aktiv </label>
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="record"
          id="record2"
          [value]="false"
          [checked]="!this.room.record"
          [(ngModel)]="this.room.record"
        />
        <label class="form-check-label" for="record2"> Inaktiv </label>
      </div>
      <small class="ps-2 text-muted form-text"
        >Wenn aktiviert, kann der Moderator die Aufnahme starten.</small
      >
    </div>
  </div>
</fieldset>
