import { Component, Input, OnInit } from '@angular/core';
import { BbbGuest } from '@reflact/kick';
import { BbbService, Recording } from 'src/app/bbb.service';

@Component({
  selector: 'app-rec-guest-list',
  templateUrl: './rec-guest-list.component.html',
  styles: ``
})
export class RecGuestListComponent implements OnInit {
  @Input() public record: Recording;
  public list: BbbGuest[] = [];

  constructor(private service: BbbService) { }

  public async ngOnInit() {
    if (this.record.metadata.aftershowPartyList) {
      this.list = this.record.metadata.aftershowPartyList;
    } else {
      this.list = [];
    }
  }

  public async addGuest(event) {
    for (const guest of event) {
      this.list.push(guest);
    }
    this.service.addUserToAftershowPartyList(this.record._id, event);
  }

  public async deleteGuest(event) {
    for (const guest of event) {
      this.list = this.list.filter(g => g.id !== guest.id);
    }
    this.service.deleteUserFromAftershowPartyList(this.record._id, event);
  }

  public async updateGuest(event) {
    this.service.updateUserInAftershowPartyList(this.record._id, event);
  }
}
