import { Component, OnInit, TemplateRef } from '@angular/core';
import { SidekickServerConf } from '@reflact/kick';
import { RagDatasupplyFrontendService } from '@reflact/rag-datasupply';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LicenseService } from '../license.service';

@Component({
  selector: 'app-server-table',
  templateUrl: './server-table.component.html',
  styles: [
  ],
  providers: [RagDatasupplyFrontendService]
})
export class ServerTableComponent implements OnInit {

  public cluster: string[] = [
    "gak", "gak27",
    "develop", "develop27",
    "kick", "kick27"
  ];
  public modalRef?: BsModalRef;
  constructor(
    public frontendService: RagDatasupplyFrontendService<SidekickServerConf>,
    public licenseService: LicenseService,
    public modalService: BsModalService
  ) { }

  public openModal(template: TemplateRef<BsModalRef>, data: Partial<SidekickServerConf>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
    this.modalRef.content = { data }
  }

  public async ngOnInit(): Promise<void> {
    await this.loadServers()
  }

  public async createServer(data: Omit<SidekickServerConf, "_id">) {
    await this.licenseService.createKickServer(data)
    this.modalService.hide();
    this.modalRef = undefined;
    await this.loadServers()
  }

  public async updateServer(data: SidekickServerConf) {
    await this.licenseService.updateKickServer(data._id, data)
    this.modalService.hide();
    this.modalRef = undefined;
    await this.loadServers()
  }

  public async confirmConfirmDelete(id: string) {
    if (confirm("Bist du dir wirklich wirklich sicher?")) {
      await this.licenseService.deleteKickServer(id)
      this.modalService.hide();
      this.modalRef = undefined;
      await this.loadServers()
    }
  }

  public openSSH(url: string) {
    window.open("ssh://root@" + url);
  }

  public async loadServers() {
    const servers = await this.licenseService.getKickServers();
    this.frontendService.fromArray(servers)
  }
}
