import { Injectable } from '@angular/core';
import { ClientToServerEvents, ServerToClientEvents } from '@reflact/kick';
import { Socket, io } from 'socket.io-client';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private static socket_: Socket<ServerToClientEvents, ClientToServerEvents>;

  public isDevelopHost() {
    return (
      window.location.host.startsWith('develop') ||
      window.location.hostname == 'localhost'
    );
  }

  public get socket(): Socket<ServerToClientEvents, ClientToServerEvents> {
    if (!SocketService.socket_) {
      SocketService.socket_ = io(window.location.origin, {
        path: '/api/socket.io',
        auth: { token: localStorage.getItem('bbbJWT') },
      });
    }
    return SocketService.socket_;
  }
}
