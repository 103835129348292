import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BBBMandantConf } from '@reflact/kick';
import { AuthService } from 'src/app/shared/AuthService';
import { BbbService } from '../bbb.service';

@Component({
  selector: 'app-global-settings',
  templateUrl: './global-settings.component.html',
  styles: [],
})
export class GlobalSettingsComponent implements OnInit {
  public config: BBBMandantConf;
  public logoUrl = '';
  @ViewChild('logoUpload') public logoUpload: ElementRef;

  constructor(
    public authService: AuthService,
    public service: BbbService,
  ) {
    this.loadMandantConfig();
  }

  public ngOnInit(): void {
    this.logoUrl =
      '/api/accountlogo/' + this.authService.getUserObject().short;
  }

  public async uploadFile() {
    const file = this.logoUpload.nativeElement.files[0];
    const result = await this.service.uploadAccountLogo(file, 'file');
    if (result != null) {
      this.logoUrl =
        '/api/accountlogo/' +
        this.authService.getUserObject().short +
        '?' +
        Math.random();
    }
  }

  private loadMandantConfig() {
    if (!this.service.mandantConfig) {
      setTimeout(this.loadMandantConfig.bind(this), 200);
    } else {
      this.config = this.service.mandantConfig;
      if (!this.config.primaryColor) {
        this.config.primaryColor = '#122E7A';
      }
      if (!this.config.sidekickColor) {
        this.config.sidekickColor = '#122E7A';
      }
    }
  }

  public updateMandantConf() {
    this.uploadFile();
    this.service.updateBBBMandantConf(this.config);
  }

  public async resetMandantConf() {
    await this.service.deleteAccountLogo();
    this.config.webcamsOnlyForModerators = false;
    this.config.muteOnStart = false;
    this.config.primaryColor = '#122E7A';
    this.config.sidekickColor = '#122E7A';
    this.config.welcomeMessage = '';
    this.logoUrl = '/api/accountlogo?' + Math.random();
    this.service.updateBBBMandantConf(this.config);
  }
}