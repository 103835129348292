import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/shared/login.service';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public email: string = "";
  public code: string = "";
  public message = "";
  public state: 'email' | 'code' | 'tan' | 'mandant' = 'email';
  public mandants: string[] = [];
  public mandant: string = "";
  private redirect: string = '';
  public tanNumber: string = "";
  public tan: string = "";
  public tanActive: boolean = false;

  constructor(public router: Router, public loginService: LoginService, private route: ActivatedRoute) {
    this.redirect = '';
  }

  public ngOnInit() {
    this.route.paramMap.subscribe(paramMap => {
      const redirect = paramMap.get('redirect');
      if (redirect) {
        this.redirect = redirect;
      }
    });
  }

  public login() {
    if (this.state == "email") {
      this.sendCode();
    } else if (this.state == "code") {
      this.loginWithCode();
    } else if (this.state == "tan") {
      this.loginWithTan();
    }
  }

  public async sendCode() {
    try {
      await this.loginService.sendLoginCodeForEmail(this.email);
      this.tanActive = await this.loginService.checkTanActive(this.email);
    } catch (e) {
      this.message = 'Überprüfe deine E-Mails oder versuche es in 10 Minuten erneut';
    }
    this.state = 'code';
  }

  public async loginWithCode() {
    try {
      const res = await this.loginService.loginWithCode(this.email, this.code);
      this.message = '';
      if (res.shorts) {
        this.mandants = res.shorts
        this.state = "mandant";
      } else {
        this.navigate();
      }
    } catch (e) {
      this.message = "Überprüfe deinen Code oder versuche es in 10 Minuten erneut";
    }
  }

  public async selectMandant(mandant: string) {
    let res = null;
    if (this.code != '') {
      res = await this.loginService.loginWithCode(this.email, this.code, mandant);
    }
    console.log("mandant selected", res)
    if (res) {
      this.navigate();
    }
  }

  private navigate() {
    if (this.redirect != '') {
      console.log("mandant redirect", this.redirect)
      window.location.href = this.redirect;
    } else {
      console.log("mandant Navigate", this.redirect)
      window.location.href = "/"
    }
  }

  public async getTanNumber() {
    const temp = await this.loginService.getTanNumber(this.email);
    if (Object.keys(temp).length === 0) {
      this.message = "Es besteht keine freie TAN mehr. Erstellen Sie eine neue TAN-Liste.";
      this.state = "code";
    } else {
      this.tanNumber = (temp.tanIndex + 1).toString();
      this.state = "tan";
    }
  }

  public backToCode() {
    this.tanNumber = "";
    this.state = "code";
  }

  public async loginWithTan() {
    try {
      const res = await this.loginService.loginWithTan(this.email, this.tan);
      if (res.status != "ok") {
        throw new Error("Invalid TAN");
      }
      this.message = '';
      this.navigate();
    } catch (e) {
      this.message = "Es wurde eine falsche TAN eingegeben. Geben Sie die neu angefragte TAN ein.";
      this.tanNumber = "";
      this.getTanNumber();
    }
  }
}
