import { Component, Input } from '@angular/core';
import { BbbRoom } from '@reflact/kick';

@Component({
  selector: 'app-record-editor',
  templateUrl: './record-editor.component.html',
  styles: [],
})
export class RecordEditorComponent {
  @Input() public room: BbbRoom;
}
