<style type="text/css">
  body {
    border: 1px solid red !important;
  }
</style>
<div
  class="p-3 m-auto"
  style="max-width: 700px"
>
  <h6>Downloads</h6>

  <!-- {{this.files | json }} -->

  <ul
    class="list-group list-group-flush"
    *ngIf="files.length > 0"
  >
    <li
      *ngFor="let file of files"
      class="list-group-item px-0"
    >
      <div class="row">
        <div class="col">
          <a
            [href]="this.getDownloadUrlForFile(file)"
            target="_blank"
            rel="noopener"
          >
            {{ file.filename }}</a>
        </div>
        <div class="col text-right">
          <a
            [href]="this.getDownloadUrlForFile(file)"
            style="text-decoration: none"
            target="_blank"
            rel="noopener"
          >
            <i class="ri-download-2-line"></i>
          </a>
        </div>
      </div>
    </li>
  </ul>
</div>