<div style="
    background-color: red;
    width: 100px;
    height: 100px;
    position: relative;
    z-index: 9999;
    right: 0;
    top: 0;
  ">
  asd
</div>
<frameset
  cols="*,25%"
  style="position: relative; top: 0px"
>
  <frame
    allow="camera;microphone"
    [src]="this.bbbFrameSrc"
    name="bbb"
  ></frame>
  <frame
    src="https://rag.frank-friendly.com/tell/1wufo"
    name="sidekick"
  ></frame>
</frameset>
<!-- Dadurch ergeben sich zwei Framefenster, deren Inhalt hier bestimmt wird -->