<div class="text-end mt-2">
  <div
    class="btn btn-primary"
    (click)="openModal(createModal, {
      localConnection: '',
      host: '',
      hasTelco: false,
      mandant: '',
      enabled: false
  })"
  >Neuer Mandant</div>
</div>
<lib-rag-datasupply-table
  [service]="frontendService"
  [columns]="[
    {fieldName: 'short', label: 'Mandant'}
  ]"
  (onSelect)="this.openModal(editModal, $event)"
></lib-rag-datasupply-table>

<ng-template #createModal>
  <div
    class="modal-body"
    *ngIf="this.modalRef?.content?.data"
  >
    <rag-form
      [data]="this.modalRef.content.data"
      [formFields]="[
        {fieldName: 'short', label: 'Mandant-Short', type: 'text', required: true},
        {fieldName: 'email', label: 'Email', type: 'text', required: true}
      ]"
      [type]="'create'"
      (submit)="this.createMandant($event)"
    ></rag-form>
  </div>
</ng-template>

<ng-template #editModal>
  <div
    class="modal-body"
    *ngIf="this.modalRef?.content?.data"
  >
    <div class="modal-header">
      <div class="modal-title">
        <h3>{{this.modalRef.content.data.short}}</h3>
      </div>
    </div>

    <app-mandant-license
      [licenses]="this.shortToLicensesMap.get(this.modalRef.content.data.short)"
      [short]="this.modalRef.content.data.short"
      (licenseChanges)="this.loadMandants()"
    ></app-mandant-license>
  </div>
</ng-template>